const locales_de = {
    /*General*/
    "general_building-singular": "Gebäude",
    "general_building-plural": "Gebäude",
    "general_room-plan-singular": "Raumplan",
    "general_room-plan-plural": "Raumpläne",
    "general_room-singular": "Raum",
    "general_room-plural": "Räume",
    "general_neighborhood-singular": "Nachbarschaft",
    "general_neighborhood-plural": "Nachbarschaften",
    "general_booking-singular": "Buchung",
    "general_booking-plural": "Buchungen",
    "general_time": "Zeit",
    "selected": "ausgewählt",
    "general_select_option_none": "keine",
    "general_visitors": "Besucher",
    "general_meetroom_seatconf_col": "Sitzkonfiguration",

    /*Room plan*/
    "all_room-plans": "Alle Raumpläne",
    "general_meeting-room-singular": "Besprechungsraum",
    "general_meeting-room-plural": "Besprechungsräume",

    "button_manage_buildings-title": "Gebäude verwalten",
    "button_my_settings-title": "Meine Einstellungen",
    "signout": "Abmelden",
    "my_settings-dialog-title": "Meine Einstellungen",
    "notification-dialog-title": "Benachrichtigungseinstellungen",
    "notification-dialog-hint": "Wähle an, für welche Aktionen du E-Mail-Benachrichtigungen erhalten möchtest",
    "notification-option-1": "Eigene Platzbuchungen als Outlook-Termin erhalten",
    "notification-option-2": "Von mir erstellte Fremdbuchungen als Outlook-Termin erhalten",
    "notification-option-3": "Besprechungsraumbuchungen als Outlook-Termin erhalten",
    "my_settings-room_plan-title": "Standard-Raumplan",
    "my_settings-auto-default": "Automatisch bestimmen",
    "my_settings-default-warning-1": "Die momentan ausgewählte Raumpräferenz",
    "my_settings-default-warning-2": "ist im Moment nicht verfügbar. Deine Einstellungen werden wiederhergestellt wenn der Raumplan wieder verfügbar ist. Wenn du neue Einstellungen speicherst werden deine alten permanent überschrieben.",
    "my_settings-consent_title": "Zustimmung",
    "my_settings-hint_text": "Bei Platzbuchungen wird dein Name anderen Nutzern angezeigt. Stimmst du dem zu?",
    "my_settings-consent_text": "Ich stimme zu",
    "my_settings-no_consent_text": "Ich stimme nicht zu",
    "my_settings-no_consent_deletion_title": "Löschen der Sitzplatzbuchungen",
    "my_settings-no_consent_deletion_text": "Dadurch werden alle deine existierenden Sitzplatzbuchungen gelöscht.",
    "room_management_dialog-title": "Raumpläne verwalten",
    "room_public-active": "öffentlich",
    "seat_management_dialog-title": "Plätze verwalten",
    "apply_to_selected-button-text": "auf Auswahl anwenden",
    "room_hint_management_dialog_title": "Räume verwalten",
    "seat_booking_error_no_rooms_configured": "Es sind derzeit keine Räume konfiguriert. Bitte wende dich an deine Office-Managerin.",
    "seat_booking-no_consent_text": "Um einen Platz zu buchen, musst du zustimmen, dass dein Name anderen Nutzern angezeigt wird. Um deine Zustimmung zu erteilen, rufe \"Meine Einstellungen\" auf.",
    "org_unit_no_org_selected": "OrgEinheit auswählen",
    "room_plan_no_room_selected": "Raumplan auswählen",
    "room_plan_no_room_available": "Kein Raumplan vorhanden",
    "room_name-column": "Name",
    "room-name": "Raumname",
    "room_building-column": "Gebäude",
    "room_plan-column": "SVG",
    "scaleFactor-column": "Skalierung",
    "room_active-column": "aktiv",
    "room_default-column": "Default",
    "room_time-active": "nach Zeit",
    "meeting-room-seatconf-name": "Konfigurationsname",
    "meeting-room-seatconf-daysadvance": "Tage",
    "meeting-room-seatconf-image": "Bild",
    "button_upload-text": "SVG Hochladen",
    "button_svg_delete-text": "SVG Löschen",
    "button_create_room-text": "Anlegen",
    "button_close_room_management_dialog-text": "Schließen",
    "message_error_file_type": "Datei ungültig!",
    "message_success_uploaded_file": "Datei wurde hochgeladen!",
    "message_error_create_room": "Ein Raum mit dem gleichen Namen und selber Org-Einheit existiert schon im System",
    "message_no_upload_changes": "Da wurde keine Änderungen in SVG vorgenommen",
    "message_error_execute_exec_struct": "Fehler während des Speicherns in Datenbank",
    "my_bookings_mngmt_dialog-title": "Meine Buchungen",
    "docking_mngmnt_dialog-title": "Dockingstations verwalten",
    "monitor_mngmnt_dialog-title": "Monitore verwalten",
    "equipment_mngmnt_dialog-title": "Ausstattung verwalten",
    "booking_limitation_dialog_title": "Vorlauf-Konfiguration",
    "seat_configuration_dialog-title": "Konfguriere Platz",
    "seat_config_mass_apply_confirm_overwrite": "Möchtest du wirklich bestehende Sitzplatzkonfigurationen überschreiben?",
    "bookings_for_these_seats_will_be_deleted": "Die Bestätigung dieser Änderung löscht alle Sitzbuchungen für diese Sitze:",
    "seat_config_apply_confirm_bookings_deletion": "Die Änderung dieser Konfiguration löscht alle Sitzbuchungen für diesen Platz. Bist du dir sicher?",
    "docking_station-column": "Dockingstations",
    "monitor-column": "Monitore",
    "height-adjustable-column": "höhenverstellbar",
    "height-adjustable-checkbox-label": "Tisch ist höhenverstellbar",
    "seat-is-bookable-checkbox-label": "Sitzplatz ist buchbar",
    "meetingroom-is-bookable-checkbox-label": "Besprechungsraum ist buchbar",
    "select_monitor": "Monitor auswählen",
    "select_docking_station": "Dockingstation auswählen",
    "inv_mngmnt_close_dialog_button-text": "Schließen",
    "inv_mngmnt_add_button-text": "Hinzufügen",
    "inv_mngmnt_edit_button-text": "Editieren",
    "inv_mngmnt_delete_button-text": "Löschen",
    "equipment_mngmnt_add_button-text": "Hinzufügen",
    "equipment_mngmnt_close_dialog_button-text": "Schließen",
    "equipment_mngmnt_edit_button-text": "Editieren",
    "equipment_mngmnt_delete_button-text": "Löschen",
    "confirm_dialog_ok_button-text": "Bestätigen",
    "confirm_dialog_cancel_button-text": "Abbrechen",
    "seats": "Plätze",
    "seat_info_dockingstations_title": "Dockingstations",
    "seat_info_monitors_title": "Monitore",
    "button_save": "Speichern",
    "button_close": "Schließen",
    "seat_name-column": "Platzbezeichner",
    "bookable-column": "buchbar",
    "room_name_column": "Raum",
    "room_note_text_column": "Hinweis-Text",
    "configure_seat": "Platz konfigurieren",
    "inv_mngmnt_error_create_inv": "Ein Inventar mit diesem Namen existiert bereits im System",
    "equipment_mngmnt_error_create_equipment": "Eine Ausstattung mit diesem Namen existiert bereits im System.",
    "equipment_mngmnt_error_delete_equipment": "Beim Löschen der Ausstattung ist ein Fehler aufgetreten.",
    "roomplan_removeseat_or_book_for_other_day_title": "Dieser Platz gehört dir an diesem Tag bereits.",
    "roomplan_removemeetingroom_or_book_for_other_day_title": "Dieser Raum gehört dir an diesem Tag bereits.",
    "roomplan_removeseat_or_book_for_other_day_infotext": "Möchtest du deine Buchung löschen oder ihn für weitere Tage buchen?",
    "button_delete_booking": "Buchung löschen",
    "button_book_other_days": "Andere Tage buchen",
    "open_another_booking_dialog": "Andere Tage/Zeiten buchen",
    "multibookingdialog_calendar_title": "Kalender",
    "multibookingdialog_textfield_label_booking_for": "Buchung für",
    "multibookingdialog_dropdown_label_booking_for": "Buchung für",
    "multibookingdialog_legend_title": "Legende",
    "multibookingdialog_legend_own_booking": "Platz gebucht durch mich",
    "multibookingdialog_legend_no_booking": "Platz frei",
    "multibookingdialog_meetingroom_legend_own_booking": "Raum gebucht durch mich",
    "multibookingdialog_meetingroom_legend_no_booking": "Raum frei",
    "multibookingdialog_legend_booking_available": "Nur bestimmte Zeitfenster verfügbar",
    "multibookingdialog_legend_other_user": "Platz nicht verfügbar",
    "multibookingdialog_meetingroom_legend_other_user": "Raum nicht verfügbar",
    "multibookingdialog_legend_marked": "Zum Buchen markierte Tage",
    "multibookingdialog_title_normal": "{{type}} buchen",
    "multibookingdialog_title_booking_type_meeting_room": "Besprechungsraum",
    "multibookingdialog_title_booking_type_seat": "Platz",
    "multibookingdialog_error_booking_not_complete": "Es konnten nicht alle ausgewählten Tage gebucht werden. Die Auswahl der Tage wurde entsprechend angepasst. Bitte erneut versuchen.",
    "multibookingdialog_error_seat_already_taken": "Der Platz ist an diesem Tag bereits belegt.",
    "multibookingdialog_meetingroom_error_seat_already_taken": "Der Raum ist in diesem Zeitfenster bereits belegt.",
    "multibookingdialog_error_max_booking_days_exceeded": "Vorausbuchung nur möglich bis zum ",
    "multibookingdialog_error_max_bookable_days_": "Maximale Anzahl buchbarer Tage erreicht, Platzbuchung an weiteren Tagen ist nicht mehr möglich.",
    "multibookingdialog_meetingroom_error_max_bookable_days_": "Maximale Anzahl buchbarer Tage erreicht, Raumbuchung an weiteren Tagen ist nicht mehr möglich.",
    "multibookingdialog_dropdown_booking_for_me": "Buchung für mich selbst",
    "multibookingdialog_dropdown_booking_for_others": "Fremdbuchung",
    "multibookingdialog_error_parallel_booking": "Ein anderer Nutzer hat den Platz gerade am %s gebucht. Der Tag ist daher nicht mehr buchbar.",
    "multibookingdialog_error_parallel_bookings": "Ein anderer Nutzer hat den Platz gerade an den Tagen %s gebucht. Die Tage sind daher nicht mehr buchbar.",
    "multibookingdialog_meetingroom_error_parallel_booking": "Ein anderer Nutzer hat den Raum gerade am %s gebucht. Der Tag ist daher nicht mehr buchbar.",
    "multibookingdialog_meetingroom_error_parallel_bookings": "Ein anderer Nutzer hat den Raum gerade an den Tagen %s gebucht. Die Tage sind daher nicht mehr buchbar.",
    "multibookingdialog_time_window_title": "Freie Zeitfenster",
    "multibookingdialog_meetingroom_settings_title": "Einstellungen",
    "multibookingdialog_meetingroom_settings_meeting_name": "Name der Besprechung",
    "multibookingdialog_meetingroom_settings_participant_number": "Teilnehmerzahl",
    "multibookingdialog_meetingroom_settings_participant_number_recommendation": "Die maximal empfohlene Teilnehmeranzahl für den Raum ist {{capacity}}",
    "multibookingdialog_time_window_no_common_time_slot": "Kein gemeinsames freies Zeitfenster an den ausgewählten Tage.",
    "multibookingdialog_error_time_slot_not_bookable": "Ausgewähltes Zeitfenster ist nicht buchbar.",
    "multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past": "Endzeit darf nicht in der Vergangenheit liegen.",
    "multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past_cannot_edit": "Besprechung in der Vergangenheit kann nicht bearbeitet werden",
    "button_book_other_days_times": "Andere Tage/Zeiten buchen",
    "meetingroom_booking_type_internal": "Intern",
    "meetingroom_booking_type_external": "Extern",
    "meetingroom_booking_type_vip": "VIP",
    "book": "Buchen",
    "cancel": "Abbrechen",
    "owner-column": "Platzbesitzer",
    "select_user": "Nutzer auswählen",
    "no_owner": "Kein Besitzer",
    "no_user": "Kein Nutzer",
    "apply": "Übernehmen",
    "seat-adjustable-height-hover": "Höhenverstellbar",
    "expand-seat-filters": "Filter ausklappen",
    "collapse-seat-filters": "Filter einklappen",
    "delete_room_dialog_content": "Wirklich löschen?",
    "delete_dialog_content": "Wirklich löschen?",
    "jsLocalDateProperty": "de-DE",
    "date": "Datum",
    "delete": "Löschen",
    "edit": "Editieren",
    "roomplan": "Raumplan",
    "seat": "Platz",
    "find_room_label": "Raum finden",
    "rooms": "Räume",
    "no_seats_available": "Keine Sitzplätze verfügbar",
    "anonymous":"anonym",
    /* Support Dialog */
    "support_dialog-title": "Support",
    "support_dialog-text": "Bei Problemen schreibe bitte eine Mail an",
    /*Own Bookings*/
    "own_bookings_management_dialog_title": "Meine Buchungen",
    "own_bookings_management_alert_dialog_title": "Ausgewählte Buchungen wirklich löschen?",
    "own_bookings_management_alert_dialog_amount_bookings": "Anzahl ausgewählter Buchungen",
    "timeWindow": "Zeitfenster",
    /*RoomManager*/
    "rm_showUploadConfirmationDialog_cancel_button-text": "Abbrechen",
    "rm_showUploadConfirmationDialog-text-seats": "Die folgenden Plätze sind nicht im neuen Raumplan vorhanden, Buchungen für diese Plätze gehen verloren: ",
    "rm_showUploadConfirmationDialog-text-meetingRooms": "Die folgenden Besprechungsräume sind nicht im neuen Raumplan vorhanden, Buchungen für diese Besprechungsräume gehen verloren: ",
    "rm_showUploadConfirmationDialog_refresh_button-text": "Aktualisieren",
    "rm_showUploadConfirmationDialog-text-seats-update": "Die folgenden Plätze werden aktualisiert: ",
    "rm_showUploadConfirmationDialog-text-meetingRooms-update": "Die folgenden Besprechungsräume werden aktualisiert: ",
    "rm_uploadInconsistentDialog-content": "Die folgenden Nachbarschaften nicht existieren: ",
    "rm_uploadInconsistentDialog-close-button": "Schliessen",
    "daily_bookings_table_orgunit_column": "Org-Einheit",
    "booking-error_title": "Buchungsfehler",
    "default_error": "Bei deiner Anfrage ist etwas schiefgelaufen.",
    "booking_error": "Bei deiner Buchung ist etwas schiefgelaufen.",
    "seat_not_bookable_error": "Platz ist derzeit nicht buchbar",
    "error_collection": "Aufgetretene Fehler",
    "error_message": "Fehlernachricht",
    "custom_error_message": "Benutzerdefinierte Nachricht",
    "stack_trace": "Stack Trace",
    "max_bookable_days": "Max. Anzahl buchbarer Tage",
    "max_lead_time": "Max. zeitlicher Vorlauf in Tagen",
    "minutes": "Minuten",
    "message_disable_time_bookings": "Es gibt Plätze mit mehreren Buchungen an einem Tag. In solchen Fällen wird die früheste Buchung zur Ganztagesbuchung und die weiteren Buchungen am selben Tag werden gelöscht. Ingesamt würden %number Buchungen gelöscht. Wirklich ändern?",
    "message_disable_time_booking": "Es gibt Plätze mit mehreren Buchungen an einem Tag. In solchen Fällen wird die früheste Buchung zur Ganztagesbuchung und die weiteren Buchungen am selben Tag werden gelöscht. Ingesamt wird eine Buchung gelöscht. Wirklich ändern?",
    'error-boundary-default-error': 'Etwas ist schief gelaufen.',
    'svg_upload_aborted_title': "SVG-Upload abgebrochen",
    'svg_upload_aborted_text': "SVG-Upload abgebrochen, bitte versuchen Sie es später noch einmal.",
    /*Neighborhoods*/
    "neighborhood_management_dialog-title": "Nachbarschaften verwalten",
    "neighborhoods": "Nachbarschaften",
    "no_neighborhood_selected": "Nachbarschaft auswählen",
    "all_neighborhoods": "Alle Nachbarschaften",
    "neighborhood_restricted_label": "Begrenzt",
    "neighborhood_access_rights": "Zugangsrechte",
    "neighborhood_admin_rights": "Administratorrechte",
    "neighborhood_confirm_overwrite": "Buchungen von Nutzern, die Zugangsrechte durch die Änderungen der Nachbarschaftseinstellungen verloren haben, werden gelöscht. Möchtest du fortfahren?",
    "neighborhood_name_collision_title": "Name der Nachbarschaft existiert bereits",
    "neighborhood_name_collision_text": "Nachbarschaftsnamen müssen innerhalb des Raumplans einzigartig sein.",
    "neighborhood_name_empty_title": "Name der Nachbarschaft ist leer",
    "neighborhood_name_empty_text": "Name der Nachbarschaft darf nicht leer sein.",
    "neighborhood_id_too_long_title": "Die Nachbarschafts id ist zu lang.",
    "neighborhood_id_too_long_text": "Die Nachbarschafts id ist zu lang, id: .",
    /*Building Manager Component*/
    "building_mngmnt_dialog-title": "Gebäude verwalten",
    "building-column": "Gebäude",
    "building-create-button": "Hinzufügen",
    "building-manage-button": "Verwalten",
    "building-delete-button": "Löschen",
    "building-close-button": "Schließen",
    "building-error-create-alert-title": "Gebäude existiert bereits",
    "building-manage-title": "Gebäude verwalten",
    "building-floor-column": "Etage",
    "building-org-unit-column": "Org-Einheit",
    "building-room-plan-name-column": "Raumplan-Name",
    "building-remove-button": "Entfernen",
    "building-assign-button": "Zuweisen",
    "building-available-room-plans-title": "Verfügbare Raumpläne",
    "building-room_active-column": "Aktive",
    "building-room_public-active": "Öffentlich",
    "building-room-with-no-org-unit": "Räume ohne Org-Einheit",
    "building-name-of-building": "Gebäudename",
    "seat-config-email": "E-Mail zur Sitzplatzkonfiguration",
    "seat-config-days-in-advance-message": "Änderungen der Sitzkonfiguration müssen mindestens {{days}} Tage im Voraus bestellt werden",
    "building-add-text": "Gebäude hinzufügen",
    "not_materna_employee": "Du kannst Sit2Gether nicht nutzen, weil es für dein Unternehmen noch nicht eingeführt wurde",
    "building-mange-role-selector": "Bearbeitungsrechte verwalten",
    "room_plan_manage_building": "Gebäude",
    /*MeetingRoomBookingManager*/
    "meeting_room_booking_manager_dialog-title": "Besprechungsraumbuchungen",
    "creator-of-booking": "Gastgeber",
    "meeting-room-name": "Raum",
    "neighborhood-name": "Nachbarschaft",
    "room-level": "Etage",
    "start-time": "Von",
    "end-time": "Bis",
    "edit-meeting-room-bookings": "Besprechungsraumbuchungen bearbeiten",
    "create-meeting-room-bookings": "Buchung für Besprechungsraum erstellen",
    "meeting-name": "Name der Besprechung",
    "number-of-guests": "Teilnehmerzahl",
    "booking-type": "Buchungstyp",
    "edit_button-text": "Editieren",
    /*Meeting Rooms*/
    "meeting_rooms_management_dialog-title": "Besprechungsräume verwalten",
    "button_meeting_rooms": "Besprechungsräume",
    "meeting_room_table_name_column": "Name",
    "meeting_room_table_id_column": "ID",
    "meeting_room_table_capacity_column": "Kapazität",
    "meeting_room_table_type_column": "Typ",
    "meeting_room_table_equipment_column": "Ausstattung",
    "meeting_room_table_bookable_column": "Buchbar",
    "configure_meeting_room": "Konferenzraum konfigurieren",
    "meeting_room_manage_rights": "Buchungsrechte verwalten",
    "meeting_room_name_tooltip": "Der Name muss eindeutig und 1 bis 60 Zeichen lang sein.",
    "meeting_room_capacity": "Kapazität",
    "meeting_room_manage_type": "Typ",
    "meeting_room_capacity_tooltip": "Die Kapazität muss eine Zahl von 0 bis 200 sein.",
    "meeting_room_name_exists_error_text": "Konferenzräum Name existiert bereits",
    "meeting_room_invalid_capacity_error_text": "Die Kapazität muss eine Zahl von 0 bis 200 sein.",
    "meeting_room_invalid_name_error_text": "Der Name muss 1 bis 60 Zeichen lang sein.",
    "internal_server_error_text": "Es ist ein interner Serverfehler aufgetreten.",
    "unknown_error_text": "Es ist ein unbekannter Serverfehler aufgetreten.",
    "meeting_room_type": "Typ",
    "ground_floor": "Erdgeschoss",
    "meetingroom_type_classroom": "Schulungsraum",
    "meetingroom_type_internal": "Intern",
    "meetingroom_type_neighborhood": "Nachbarschaft",
    "meetingroom_type_visitors_center": "Besucherzentrum",
    "meeting_room_table_days_in_advance_column": "Sitzkonfiguration im Voraus buchen",
    "meeting_room_table_image_column": "Bild ",
    "meeting_room_seat_config_upload_image_tooltip": "Bild hochladen",
    "meeting_room_seat_config_delete_image_tooltip": "Bild löschen",
    "meeting_room_booking_settings_config_option": "Sitzkonfiguration",
    "meeting_room_booking_settings_config_option_default": "Keine Änderung",
    "meeting_room_config_show_seat_config": "Sitzkonfiguration anzeigen",
    "meeting_room_config_close_modal": "Schließen",

    /* MeetingRoomEquipment */
    "general_equipment-singular": "Ausstattung",

    "alt_first_name_multiple_bookings_same_seat": "Mehrere",
    /* Own Booking Manager */
    "meeting_rooms": "Besprechungsräume",
    "time": "Zeit",
    "number_of_participants": "Teilnehmer",
    "meeting_name": "Name der Besprechung",

    "entries_per_page": "Einträge pro Seite",
    "pagination_of": "von",

    "update_seat_configs_partially_failed": "Einige Sitzplätze konnten nicht konfiguriert werden.",

    /* Reporting */
    "reporting-dialog-title": "Sitzplatzauslastung",
    "reporting-utilization-label": "Auslastung in Prozent",
    "reporting-dat-label": "Datum",
    "reporting-bookable-seats-label": "Anzahl der buchbaren Plätze",
    "reporting-booked-seats-label": "Anzahl der gebuchten Plätze",
    "reporting-dialog-download-csv-button-text":"CSV herunterladen",
    "reporting-close-dialog-button-text": "Schließen",
    "reporting-filter-building-label":"Gebäude",
    "reporting-filter-room-label":"Raum",
    "reporting-filter-neighborhood-label":"Nachbarschaften",
    "reporting-filter-assigned-seats-label":"Dauerhaft zugewiesene Plätze",
    "reporting-filter-data-available-label": "Berichtsdaten verfügbar ab",
    "reporting-dialog-filter-all-rooms":"Alle Raumpläne",
    "reporting-dialog-filter-all-neighborhood":"Alle Nachbarschaften",
    "reporting-dialog-filter-no-neighborhood":"Keine Nachbarschaft",
    "reporting-filter-assigned-seats-dont-count":"Nicht mitzählen",
    "reporting-filter-assigned-seats-count-as-booked":"Als gebucht zählen",
    "reporting-filter-assigned-seats-count-as-not-booked":"Als nicht gebucht zählen",
    "reporting-filter-button-text": "Bericht generieren",

    /* Mobile specific text */
    "mobile_show_filter": "Filter",
    "mobile_hide_filter": "Minimieren",

    /* room finder */
    "roomfinder_no_options_text": "Keine Optionen",

    /* Meeting Room Booking Manager Neighborhood Filter */
    "Meeting_Room_Bookings_Neighborhood_Filter_Label": "Nachbarschafts-Besprechungsräume",
    "Meeting_Room_Bookings_Neighborhood_Filter_All_Neighborhoods": "Aus allen Nachbarschaften",
    "Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood": "Nur aus meiner Nachbarschaft",

    /* Meeting Room Booking Manager Type Filter*/
    "Meeting_Room_Bookings_Type_Filter_Label": "Typ",
    "Meeting_Room_Bookings_Type_Filter_All": "Alle Typen",

    /*Meeting Room Booking Manager Min Capacity Filter*/
    "Meeting_Room_Bookings_Min_Cap_Filter_Label": "Mindestkapazität",

    /* Meeting Room Booking Manager Filter Mobile*/
    "meeting_room_bookings_show_filter": "Filteroptionen anzeigen",
    "meeting_room_bookings_hide_filter": "Filteroptionen ausblenden",

    /*
    Header Notification
     */
    "save-button-text": "Speichern",
    "cancel-button-text": "Abbrechen",
    "close-button-text": "Schließen",
    "user-info-text-menu-item": "Nutzer-Infotext",
    "user-info-text-header": "Ankündigung",
    "user-info-text-importance": "Wichtig",

    // Error messages:
    "error_loading_bookings": "Fehler beim Laden der Buchungen",
    "error_deleting_bookings": "Fehler beim Löschen der Buchungen"
}
export default locales_de;
