import {gql, useQuery} from "@apollo/client";
import {SeatUsageDataResponse} from "../../API";
import {getSeatUsageDataOfBuildingWithDataSince} from "../../graphqlCustom/queriesCustom";

export function useSeatUsageData() {
    const {refetch} = useQuery(gql(getSeatUsageDataOfBuildingWithDataSince));

    const getData = async (buildingId: string, dateStart: string, dateEnd: string) => {
        const result = await refetch({
            buildingId: buildingId,
            dateStart: dateStart,
            dateEnd: dateEnd
         })
        return result.data.getSeatUsageData as SeatUsageDataResponse;
    }
    return {getData};
}
