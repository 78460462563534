/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPresignedRoomplanUrl = /* GraphQL */ `query GetPresignedRoomplanUrl(
  $writeAccess: Boolean!
  $roomId: String!
  $roomplanId: String!
) {
  getPresignedRoomplanUrl(
    writeAccess: $writeAccess
    roomId: $roomId
    roomplanId: $roomplanId
  )
}
` as GeneratedQuery<
  APITypes.GetPresignedRoomplanUrlQueryVariables,
  APITypes.GetPresignedRoomplanUrlQuery
>;
export const getPresignedImageUrl = /* GraphQL */ `query GetPresignedImageUrl(
  $writeAccess: Boolean!
  $roomId: String!
  $imageId: String!
) {
  getPresignedImageUrl(
    writeAccess: $writeAccess
    roomId: $roomId
    imageId: $imageId
  )
}
` as GeneratedQuery<
  APITypes.GetPresignedImageUrlQueryVariables,
  APITypes.GetPresignedImageUrlQuery
>;
export const getRoomPlanExecutionStructure = /* GraphQL */ `query GetRoomPlanExecutionStructure($items: ActionQueryItemsInput) {
  getRoomPlanExecutionStructure(items: $items)
}
` as GeneratedQuery<
  APITypes.GetRoomPlanExecutionStructureQueryVariables,
  APITypes.GetRoomPlanExecutionStructureQuery
>;
export const getRooms = /* GraphQL */ `query GetRooms {
  getRooms {
    items {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.GetRoomsQueryVariables, APITypes.GetRoomsQuery>;
export const getMeetingRooms = /* GraphQL */ `query GetMeetingRooms($roomIds: [String]) {
  getMeetingRooms(roomIds: $roomIds) {
    items {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      meetingRoomSeatConfIds
      isBookable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomsQueryVariables,
  APITypes.GetMeetingRoomsQuery
>;
export const getMeetingRoomsByBuildingId = /* GraphQL */ `query GetMeetingRoomsByBuildingId($buildingId: ID!) {
  getMeetingRoomsByBuildingId(buildingId: $buildingId) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomsByBuildingIdQueryVariables,
  APITypes.GetMeetingRoomsByBuildingIdQuery
>;
export const getMeetingRoomBookings = /* GraphQL */ `query GetMeetingRoomBookings(
  $roomIds: [String!]
  $meetingRoomIds: [String]
  $date: String
  $dateTo: String
  $bookerId: String
) {
  getMeetingRoomBookings(
    roomIds: $roomIds
    meetingRoomIds: $meetingRoomIds
    date: $date
    dateTo: $dateTo
    bookerId: $bookerId
  ) {
    items {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      meetingRoomSeatConfId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomBookingsQueryVariables,
  APITypes.GetMeetingRoomBookingsQuery
>;
export const getSeatBookings = /* GraphQL */ `query GetSeatBookings($input: GetSeatBookingsInput) {
  getSeatBookings(input: $input) {
    items {
      bookingId
      date
      seatId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetSeatBookingsQueryVariables,
  APITypes.GetSeatBookingsQuery
>;
export const getHintsByBuilding = /* GraphQL */ `query GetHintsByBuilding($buildingId: ID!) {
  getHintsByBuilding(buildingId: $buildingId) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetHintsByBuildingQueryVariables,
  APITypes.GetHintsByBuildingQuery
>;
export const getInventories = /* GraphQL */ `query GetInventories {
  getInventories {
    items {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetInventoriesQueryVariables,
  APITypes.GetInventoriesQuery
>;
export const getSeatConfigs = /* GraphQL */ `query GetSeatConfigs($roomId: ID!) {
  getSeatConfigs(roomId: $roomId) {
    items {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetSeatConfigsQueryVariables,
  APITypes.GetSeatConfigsQuery
>;
export const getInventoryByLowerCasedName = /* GraphQL */ `query GetInventoryByLowerCasedName($input: InventoryByLowerCasedNameInput) {
  getInventoryByLowerCasedName(input: $input) {
    items {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetInventoryByLowerCasedNameQueryVariables,
  APITypes.GetInventoryByLowerCasedNameQuery
>;
export const getNeighborhoodsByRoomId = /* GraphQL */ `query GetNeighborhoodsByRoomId($roomId: ID!, $checkPermissions: Boolean) {
  getNeighborhoodsByRoomId(
    roomId: $roomId
    checkPermissions: $checkPermissions
  ) {
    items {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetNeighborhoodsByRoomIdQueryVariables,
  APITypes.GetNeighborhoodsByRoomIdQuery
>;
export const getAllNeighborhoods = /* GraphQL */ `query GetAllNeighborhoods {
  getAllNeighborhoods {
    items {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetAllNeighborhoodsQueryVariables,
  APITypes.GetAllNeighborhoodsQuery
>;
export const getSeatUsageData = /* GraphQL */ `query GetSeatUsageData(
  $buildingId: ID!
  $dateStart: AWSDate!
  $dateEnd: AWSDate!
) {
  getSeatUsageData(
    buildingId: $buildingId
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    items {
      buildingId
      date
      bookable
      booked
      reserved
    }
    dataSince {
      dataSince
    }
  }
}
` as GeneratedQuery<
  APITypes.GetSeatUsageDataQueryVariables,
  APITypes.GetSeatUsageDataQuery
>;
export const getBuilding = /* GraphQL */ `query GetBuilding($buildingId: ID!) {
  getBuilding(buildingId: $buildingId) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetBuildingQueryVariables,
  APITypes.GetBuildingQuery
>;
export const listBuildings = /* GraphQL */ `query ListBuildings(
  $buildingId: ID
  $filter: ModelBuildingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBuildings(
    buildingId: $buildingId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      buildingId
      buildingName
      roleIds
      buildingNameLowerCased
      seatConfEmail
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListBuildingsQueryVariables,
  APITypes.ListBuildingsQuery
>;
export const getRoom = /* GraphQL */ `query GetRoom($roomId: ID!) {
  getRoom(roomId: $roomId) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetRoomQueryVariables, APITypes.GetRoomQuery>;
export const listRooms = /* GraphQL */ `query ListRooms(
  $roomId: ID
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRooms(
    roomId: $roomId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListRoomsQueryVariables, APITypes.ListRoomsQuery>;
export const getMeetingRoom = /* GraphQL */ `query GetMeetingRoom($meetingRoomId: ID!, $roomId: ID!) {
  getMeetingRoom(meetingRoomId: $meetingRoomId, roomId: $roomId) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomQueryVariables,
  APITypes.GetMeetingRoomQuery
>;
export const listMeetingRooms = /* GraphQL */ `query ListMeetingRooms(
  $meetingRoomId: ID
  $roomId: ModelIDKeyConditionInput
  $filter: ModelMeetingRoomFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMeetingRooms(
    meetingRoomId: $meetingRoomId
    roomId: $roomId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      meetingRoomSeatConfIds
      isBookable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingRoomsQueryVariables,
  APITypes.ListMeetingRoomsQuery
>;
export const getNeighborhood = /* GraphQL */ `query GetNeighborhood($neighborhoodId: ID!, $roomId: ID!) {
  getNeighborhood(neighborhoodId: $neighborhoodId, roomId: $roomId) {
    neighborhoodId
    roomId
    restricted
    roleIds
    name
    adminRoleIds
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetNeighborhoodQueryVariables,
  APITypes.GetNeighborhoodQuery
>;
export const listNeighborhoods = /* GraphQL */ `query ListNeighborhoods(
  $neighborhoodId: ID
  $roomId: ModelIDKeyConditionInput
  $filter: ModelNeighborhoodFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNeighborhoods(
    neighborhoodId: $neighborhoodId
    roomId: $roomId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListNeighborhoodsQueryVariables,
  APITypes.ListNeighborhoodsQuery
>;
export const getBooking = /* GraphQL */ `query GetBooking($bookingId: ID!, $date: AWSDate!) {
  getBooking(bookingId: $bookingId, date: $date) {
    bookingId
    date
    seatId
    roomId
    room {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    orgUnitAdmin
    bookingFor
    timeBegin
    timeEnd
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetBookingQueryVariables,
  APITypes.GetBookingQuery
>;
export const listBookings = /* GraphQL */ `query ListBookings(
  $bookingId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookings(
    bookingId: $bookingId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      bookingId
      date
      seatId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListBookingsQueryVariables,
  APITypes.ListBookingsQuery
>;
export const getMeetingRoomBooking = /* GraphQL */ `query GetMeetingRoomBooking($bookingId: ID!, $date: AWSDate!) {
  getMeetingRoomBooking(bookingId: $bookingId, date: $date) {
    bookingId
    date
    meetingRoomId
    roomId
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    timeBegin
    timeEnd
    meetingName
    roomCapacity
    participantNumber
    meetingType
    visitors
    meetingRoomSeatConfId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomBookingQueryVariables,
  APITypes.GetMeetingRoomBookingQuery
>;
export const listMeetingRoomBookings = /* GraphQL */ `query ListMeetingRoomBookings(
  $bookingId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelMeetingRoomBookingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMeetingRoomBookings(
    bookingId: $bookingId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      meetingRoomSeatConfId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingRoomBookingsQueryVariables,
  APITypes.ListMeetingRoomBookingsQuery
>;
export const getInventory = /* GraphQL */ `query GetInventory($inventoryId: ID!) {
  getInventory(inventoryId: $inventoryId) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetInventoryQueryVariables,
  APITypes.GetInventoryQuery
>;
export const listInventories = /* GraphQL */ `query ListInventories(
  $inventoryId: ID
  $filter: ModelInventoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInventories(
    inventoryId: $inventoryId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInventoriesQueryVariables,
  APITypes.ListInventoriesQuery
>;
export const getMeetingRoomEquipment = /* GraphQL */ `query GetMeetingRoomEquipment($equipmentId: ID!) {
  getMeetingRoomEquipment(equipmentId: $equipmentId) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomEquipmentQueryVariables,
  APITypes.GetMeetingRoomEquipmentQuery
>;
export const listMeetingRoomEquipments = /* GraphQL */ `query ListMeetingRoomEquipments(
  $equipmentId: ID
  $filter: ModelMeetingRoomEquipmentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMeetingRoomEquipments(
    equipmentId: $equipmentId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingRoomEquipmentsQueryVariables,
  APITypes.ListMeetingRoomEquipmentsQuery
>;
export const getMeetingRoomSeatConfig = /* GraphQL */ `query GetMeetingRoomSeatConfig($meetingRoomSeatConfId: ID!) {
  getMeetingRoomSeatConfig(meetingRoomSeatConfId: $meetingRoomSeatConfId) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingRoomSeatConfigQueryVariables,
  APITypes.GetMeetingRoomSeatConfigQuery
>;
export const listMeetingRoomSeatConfigs = /* GraphQL */ `query ListMeetingRoomSeatConfigs(
  $meetingRoomSeatConfId: ID
  $filter: ModelMeetingRoomSeatConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMeetingRoomSeatConfigs(
    meetingRoomSeatConfId: $meetingRoomSeatConfId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      meetingRoomSeatConfId
      buildingId
      roomId
      meetingRoomId
      name
      imageId
      daysInAdvance
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingRoomSeatConfigsQueryVariables,
  APITypes.ListMeetingRoomSeatConfigsQuery
>;
export const getSeatConfig = /* GraphQL */ `query GetSeatConfig($roomId: ID!, $seatName: String!) {
  getSeatConfig(roomId: $roomId, seatName: $seatName) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSeatConfigQueryVariables,
  APITypes.GetSeatConfigQuery
>;
export const listSeatConfigs = /* GraphQL */ `query ListSeatConfigs(
  $roomId: ID
  $seatName: ModelStringKeyConditionInput
  $filter: ModelSeatConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSeatConfigs(
    roomId: $roomId
    seatName: $seatName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSeatConfigsQueryVariables,
  APITypes.ListSeatConfigsQuery
>;
export const getBookingConfig = /* GraphQL */ `query GetBookingConfig($orgUnitId: ID!) {
  getBookingConfig(orgUnitId: $orgUnitId) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetBookingConfigQueryVariables,
  APITypes.GetBookingConfigQuery
>;
export const listBookingConfigs = /* GraphQL */ `query ListBookingConfigs(
  $orgUnitId: ID
  $filter: ModelBookingConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingConfigs(
    orgUnitId: $orgUnitId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListBookingConfigsQueryVariables,
  APITypes.ListBookingConfigsQuery
>;
export const getMailConfig = /* GraphQL */ `query GetMailConfig($userId: ID!) {
  getMailConfig(userId: $userId) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMailConfigQueryVariables,
  APITypes.GetMailConfigQuery
>;
export const listMailConfigs = /* GraphQL */ `query ListMailConfigs(
  $userId: ID
  $filter: ModelMailConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMailConfigs(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMailConfigsQueryVariables,
  APITypes.ListMailConfigsQuery
>;
export const getDefaultRoomConfig = /* GraphQL */ `query GetDefaultRoomConfig($userId: ID!) {
  getDefaultRoomConfig(userId: $userId) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetDefaultRoomConfigQueryVariables,
  APITypes.GetDefaultRoomConfigQuery
>;
export const listDefaultRoomConfigs = /* GraphQL */ `query ListDefaultRoomConfigs(
  $userId: ID
  $filter: ModelDefaultRoomConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDefaultRoomConfigs(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListDefaultRoomConfigsQueryVariables,
  APITypes.ListDefaultRoomConfigsQuery
>;
export const getUserSettings = /* GraphQL */ `query GetUserSettings($userId: ID!) {
  getUserSettings(userId: $userId) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedQuery<
  APITypes.GetUserSettingsQueryVariables,
  APITypes.GetUserSettingsQuery
>;
export const listUserSettings = /* GraphQL */ `query ListUserSettings(
  $userId: ID
  $filter: ModelUserSettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserSettings(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingsQueryVariables,
  APITypes.ListUserSettingsQuery
>;
export const getInfoText = /* GraphQL */ `query GetInfoText($id: ID!) {
  getInfoText(id: $id) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetInfoTextQueryVariables,
  APITypes.GetInfoTextQuery
>;
export const listInfoTexts = /* GraphQL */ `query ListInfoTexts(
  $id: ID
  $filter: ModelInfoTextFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInfoTexts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      infoText
      important
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInfoTextsQueryVariables,
  APITypes.ListInfoTextsQuery
>;
export const roomByBuildingId = /* GraphQL */ `query RoomByBuildingId(
  $buildingId: ID!
  $nameLowerCased: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  roomByBuildingId(
    buildingId: $buildingId
    nameLowerCased: $nameLowerCased
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RoomByBuildingIdQueryVariables,
  APITypes.RoomByBuildingIdQuery
>;
export const roomByNameAndOrgUnitID = /* GraphQL */ `query RoomByNameAndOrgUnitID(
  $orgUnitId: ID!
  $nameLowerCased: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  roomByNameAndOrgUnitID(
    orgUnitId: $orgUnitId
    nameLowerCased: $nameLowerCased
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RoomByNameAndOrgUnitIDQueryVariables,
  APITypes.RoomByNameAndOrgUnitIDQuery
>;
export const equipmentByBuildingIdAndNameLowerCased = /* GraphQL */ `query EquipmentByBuildingIdAndNameLowerCased(
  $nameLowerCased: String!
  $buildingId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingRoomEquipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  equipmentByBuildingIdAndNameLowerCased(
    nameLowerCased: $nameLowerCased
    buildingId: $buildingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.EquipmentByBuildingIdAndNameLowerCasedQueryVariables,
  APITypes.EquipmentByBuildingIdAndNameLowerCasedQuery
>;
export const seatConfigBySeatName = /* GraphQL */ `query SeatConfigBySeatName(
  $seatName: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSeatConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  seatConfigBySeatName(
    seatName: $seatName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SeatConfigBySeatNameQueryVariables,
  APITypes.SeatConfigBySeatNameQuery
>;
export const getHint = /* GraphQL */ `query GetHint($hintId: ID!, $roomId: ID!) {
  getHint(hintId: $hintId, roomId: $roomId) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetHintQueryVariables, APITypes.GetHintQuery>;
export const listHints = /* GraphQL */ `query ListHints(
  $hintId: ID
  $roomId: ModelIDKeyConditionInput
  $filter: ModelHintFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHints(
    hintId: $hintId
    roomId: $roomId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListHintsQueryVariables, APITypes.ListHintsQuery>;
export const hintsByRoom = /* GraphQL */ `query HintsByRoom(
  $roomId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelHintFilterInput
  $limit: Int
  $nextToken: String
) {
  hintsByRoom(
    roomId: $roomId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.HintsByRoomQueryVariables,
  APITypes.HintsByRoomQuery
>;
export const hintByBuildingId = /* GraphQL */ `query HintByBuildingId(
  $buildingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelHintFilterInput
  $limit: Int
  $nextToken: String
) {
  hintByBuildingId(
    buildingId: $buildingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.HintByBuildingIdQueryVariables,
  APITypes.HintByBuildingIdQuery
>;
