import {gql, useMutation, useQuery} from "@apollo/client";
import {getRoomPlanExecutionStructure} from "../graphql/queries";
import {ActionHintInput, ActionItemsInput, ActionQueryItemsInput, Hint, RoomPlanUploadActionsInput} from "../API";
import {roomPlanSecureUploadActions} from "../graphql/mutations";
import {MeetingRoomType} from "../Utils/Enums";

interface SvgHint {
    hintId: string
    roomId: string
    buildingId: string
}

interface SvgMeetingRoom {
    meetingRoomId: string
    neighborhoodId: string
    roomId: string
    name: string
    capacity: number
    type: string
}

interface SvgSeat {
    seatName: string
    neighborhoodId: string
    roomId: string
}

interface SvgNeighborhood {
    neighborhoodId: string
    roomId: string
}

export interface SvgStructure {
    hints: SvgHint[]
    meetingRooms: SvgMeetingRoom[]
    seats: SvgSeat[]
    neighborhoods: SvgNeighborhood[]
}

interface SvgInconsistentItem {
    neighborhoodId: string
    seats: SvgSeat[]
    meetingRooms: SvgMeetingRoom[]
}

export const useRoomPlan = () => {
    const {refetch: refetchExecutionStructure} = useQuery(gql(getRoomPlanExecutionStructure));
    const [executeExecutionStructureMutation] = useMutation(gql(roomPlanSecureUploadActions));

    const getInconsistentItems = (svgStructure: SvgStructure): SvgInconsistentItem[] => {
        const inconsistentItems: SvgInconsistentItem[] = []

        svgStructure.meetingRooms.forEach(meetingRoom => {
            if (meetingRoom.neighborhoodId
                && !svgStructure.neighborhoods
                    .some(neighborhood => neighborhood.neighborhoodId === meetingRoom.neighborhoodId)) {
                if (!inconsistentItems.some(item => item.neighborhoodId === meetingRoom.neighborhoodId)) {
                    inconsistentItems.push({
                        neighborhoodId: meetingRoom.neighborhoodId,
                        meetingRooms: [],
                        seats: []
                    })
                }
                const inconsistentItem = inconsistentItems.find(item => item.neighborhoodId === meetingRoom.neighborhoodId)
                if (inconsistentItem) {
                    inconsistentItem.meetingRooms.push(meetingRoom)
                }
            }
        })

        svgStructure.seats.forEach(seat => {
            if (seat.neighborhoodId
                && !svgStructure.neighborhoods
                    .some(neighborhood => neighborhood.neighborhoodId === seat.neighborhoodId)) {
                if (!inconsistentItems.some(item => item.neighborhoodId === seat.neighborhoodId)) {
                    inconsistentItems.push({
                        neighborhoodId: seat.neighborhoodId,
                        meetingRooms: [],
                        seats: []
                    })
                }
                const inconsistentItem = inconsistentItems.find(item => item.neighborhoodId === seat.neighborhoodId)
                if (inconsistentItem) {
                    inconsistentItem.seats.push(seat)
                }
            }
        })

        return inconsistentItems
    }

    const getSvgStructure = (buildingId: string, roomId: string, svg: string): SvgStructure => {
        const doc = new DOMParser().parseFromString(svg, "image/svg+xml")
        const hintNodes = doc.querySelectorAll("#offices > rect")
        const meetingRoomNodes = doc.querySelectorAll("#meetingRooms > g")
        const seatNodes = doc.querySelectorAll("#seats > g")
        const neighborhoods = doc.querySelectorAll("#neighborhoods > rect")
        return {
            hints: Array.from(hintNodes).map(item => mapHintNodeToSvgHint(buildingId, roomId, item)),
            meetingRooms: Array.from(meetingRoomNodes).map(item => mapMeetingRoomNodeToSvgMeetingRoom(roomId, item)),
            seats: Array.from(seatNodes).map(item => mapSeatNodeToSvgSeat(roomId, item)),
            neighborhoods: Array.from(neighborhoods).map(item => mapNeighborhoodNodeToSvgNeighborhood(roomId, item))
        }
    }

    const mapHintNodeToSvgHint = (buildingId: string, roomId: string, node: Element): SvgHint => {
        return {
            hintId: node.id,
            buildingId: buildingId,
            roomId: roomId
        }
    }

    const mapMeetingRoomNodeToSvgMeetingRoom = (roomId: string, node: Element): SvgMeetingRoom => {
        return {
            meetingRoomId: node.id,
            neighborhoodId: node.getAttribute("neighborhoodId") || "",
            roomId: roomId,
            name: node.querySelector(".roomName")?.getAttribute("roomName") || node.id,
            capacity: parseInt(node.querySelector(".roomCapacity")?.getAttribute("roomCap") || "0"),
            type: node.querySelector(".roomCapacity")?.getAttribute("type") || MeetingRoomType.INTERNAL
        }
    }

    const mapSeatNodeToSvgSeat = (roomId: string, node: Element): SvgSeat => {
        return {
            seatName: node.id,
            neighborhoodId: node.getAttribute("neighborhoodId") || "",
            roomId: roomId
        }
    }

    const mapNeighborhoodNodeToSvgNeighborhood = (roomId: string, node: Element): SvgNeighborhood => {
        return {
            neighborhoodId: node.id,
            roomId: roomId
        }
    }

    const executeExecutionStructure = async (actionItems: RoomPlanUploadActionsInput) => {
        console.log(`Executing action items: `, actionItems)
        return await executeExecutionStructureMutation({
            variables: {
                input: actionItems
            }
        })
    }

    const getExecutionStructure = async (roomId: string, svgStructure: SvgStructure): Promise<RoomPlanUploadActionsInput> => {
        const response = await refetchExecutionStructure({
            items: mapSvgStructureToActionItems(roomId, svgStructure)
        })
            .catch(err => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!response || !response.data || !response.data.getRoomPlanExecutionStructure) {
            return {}
        }

        return JSON.parse(response.data.getRoomPlanExecutionStructure)
    }

    const mapSvgStructureToActionItems = (roomId: string, svgStructure: SvgStructure): ActionQueryItemsInput => {
        return {
            roomId: roomId,
            hints: svgStructure.hints.map(hint => ({
                hintId: hint.hintId,
                roomId: hint.roomId,
                buildingId: hint.buildingId
            })),
            meetingRooms: svgStructure.meetingRooms.map(meetingRoom => ({
                meetingRoomId: meetingRoom.meetingRoomId,
                neighborhoodId: meetingRoom.neighborhoodId,
                roomId: meetingRoom.roomId,
                name: meetingRoom.name,
                capacity: meetingRoom.capacity,
                type: meetingRoom.type
            })),
            seats: svgStructure.seats.map(seat => ({
                seatName: seat.seatName,
                neighborhoodId: seat.neighborhoodId,
                roomId: seat.roomId
            })),
            neighborhoods: svgStructure.neighborhoods.map(neighborhood => ({
                neighborhoodId: neighborhood.neighborhoodId,
                roomId: neighborhood.roomId
            }))
        }
    }

    const isAnythingToUpdate = (executionStructure: RoomPlanUploadActionsInput) => executionStructure
        ? ((executionStructure.create?.meetingRooms?.length ?? 0) > 0)
        || ((executionStructure.create?.seats?.length ?? 0) > 0)
        || ((executionStructure.create?.hints?.length ?? 0) > 0)
        || ((executionStructure.create?.neighborhoods?.length ?? 0) > 0)
        || ((executionStructure.update?.meetingRooms?.length ?? 0) > 0)
        || ((executionStructure.update?.seats?.length ?? 0) > 0)
        || ((executionStructure.delete?.meetingRooms?.length ?? 0) > 0)
        || ((executionStructure.delete?.seats?.length ?? 0) > 0)
        || ((executionStructure.delete?.hints?.length ?? 0) > 0)
        || ((executionStructure.delete?.neighborhoods?.length ?? 0) > 0)
        : false;

    const showConfirmation = (executionStructure: RoomPlanUploadActionsInput) => executionStructure
        ? (
            ((executionStructure.update?.meetingRooms?.length ?? 0) > 0) || ((executionStructure.update?.seats?.length ?? 0) > 0)
            || ((executionStructure.delete?.meetingRooms?.length ?? 0) > 0) || ((executionStructure.delete?.seats?.length ?? 0) > 0)
        )
        :false;

    return {
        getExecutionStructure,
        executeExecutionStructure,
        getSvgStructure,
        getInconsistentItems,
        isAnythingToUpdate,
        showConfirmation
    }
}