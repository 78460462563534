export const getSeatUsageDataOfBuilding = /* GraphQL */ `query getSeatUsageDataOfBuilding($buildingId: ID!, $dateStart: AWSDate!, $dateEnd: AWSDate!) {
    getSeatUsageData(buildingId: $buildingId, dateStart: $dateStart, dateEnd: $dateEnd) {
        items {
            buildingId
            date
            bookable
            booked
            reserved
            roomData {
                roomId
                bookable
                booked
                reserved
                neighborhoodData {
                    neighborhoodId
                    bookable
                    booked
                    reserved
                }
            }
        }
    }
}

`;

export const getSeatUsageDataOfBuildingWithDataSince = /* GraphQL */ `query getSeatUsageDataOfBuildingWithDataSince($buildingId: ID!, $dateStart: AWSDate!, $dateEnd: AWSDate!) {
    getSeatUsageData(buildingId: $buildingId, dateStart: $dateStart, dateEnd: $dateEnd) {
        items {
            buildingId
            date
            bookable
            booked
            reserved
            roomData {
                roomId
                bookable
                booked
                reserved
                neighborhoodData {
                    neighborhoodId
                    bookable
                    booked
                    reserved
                }
            }
        }
        dataSince {
            dataSince
            roomsDataSince {
                roomId
                dataSince
                neighborhoodsDataSince {
                    neighborhoodId
                    dataSince
                }
            }
        }
    }
}

`;

export const getRoomSeats = /* GraphQL */ `
    query getRoomSeats($roomId: ID!) {
        getRoom(roomId: $roomId) {
            seats {
                items {
                    roomId
                    inventory
                    seatName
                    seatType
                    isSeatHeightAdjustable
                    owner
                    isOwnerRegistered
                }
            }
        }
    }
`;

export const customBookingsByBookerWithRoomObject = /* GraphQL */ `
    query BookingsByBooker(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingsByBooker(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                seatId
                roomId
                # room {
                #     name
                # }
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                bookingFor
                timeBegin
                timeEnd
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const customBookingsByBookerWithoutRoomObject = /* GraphQL */ `
    query BookingsByBooker(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingsByBooker(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                seatId
                roomId
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                bookingFor
                timeBegin
                timeEnd
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const customMeetRoomBookingsByBookerWithoutRoomObject = /* GraphQL */ `
    query MeetRoomBookingsByBooker(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelMeetingRoomBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        meetRoomBookingsByBooker(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                meetingRoomId
                roomId
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                timeBegin
                timeEnd
                meetingName
                roomCapacity
                participantNumber
                meetingType
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listBuildings = /* GraphQL */ `
    query ListBuildings(
        $buildingId: ID
        $filter: ModelBuildingFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listBuildings(
            buildingId: $buildingId
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                buildingId
                buildingName
                rooms {
                    items {
                        roomId
                        buildingId
                        orgUnitId
                        orgUnitAdmin
                        name
                        nameLowerCased
                        roomPlanExisting
                        roomPlanId
                        roomPlanScaleFactor
                        isActive
                        isTimeActive
                        isBookingDisabled
                        isDefault
                        isPublic
                        hasHints
                        floor
                        createdAt
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const getBuilding = /* GraphQL */ `
    query GetBuilding($buildingId: ID!) {
        getBuilding(buildingId: $buildingId) {
            buildingName
            rooms {
                items {
                    buildingId
                    orgUnitId
                    orgUnitAdmin
                    roomPlanExisting
                    roomPlanId
                    roomPlanScaleFactor
                    isActive
                    isTimeActive
                    isBookingDisabled
                    isDefault
                    isPublic
                    hasHints
                    floor
                    name
                    nameLowerCased
                    roomId
                }
            }
        }
    }
`;
