import React from "react";
import "./App.css";
import awsExports from "./aws-exports";
import {withLogin} from "@2gether/frontend-library";
import {Auth} from "aws-amplify"
import MainPage from "./pages/MainPage";
import {MuiThemeProvider} from "@material-ui/core";
import {maternaTheme, maternaNewTheme} from "./styles/materna_theme";
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, DefaultOptions, InMemoryCache} from "@apollo/client";
import {createAuthLink} from "aws-appsync-auth-link";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";
import Amplify from "@aws-amplify/core";
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";
import {createTheme, ThemeProvider} from "@mui/material";

Amplify.configure(awsExports);
const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

Auth.configure({
    cookieStorage: cookieStorage
});

const config = {
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    auth: {
        type: awsExports.aws_appsync_authenticationType,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
    }
};

const httpLink = createHttpLink(({uri: config.url}));

const link = ApolloLink.from([
    // @ts-ignore
    createAuthLink(config),
    // @ts-ignore
    createSubscriptionHandshakeLink(config, httpLink)
]);

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only'
    },
    query: {
        fetchPolicy: 'network-only'
    },
    mutate: {
        fetchPolicy: 'network-only'
    }
}

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        addTypename: false, typePolicies: {
            Query: {
                fields: {
                    bookingBySeatAndDate: {
                        // Don't cache separate results based on
                        // any of this field's arguments.
                        keyArgs: false,
                        // Concatenate the incoming list items with
                        // the existing list items.
                        merge(existing = {items: [], nextToken: ''}, incoming) {
                            const result = [...existing.items, ...incoming.items]
                            const key = 'bookingId';
                            const resultUniqueByKey = [...Array.from(new Map(result.map(item => [item[key], item])).values())];
                            return {
                                items: resultUniqueByKey,
                                nextToken: incoming.nextToken
                            }
                        },
                    }
                }
            }
        }
    }),
    defaultOptions: defaultOptions
});



function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <div className={"App"}>
                <ThemeProvider theme={maternaNewTheme}>
                <MuiThemeProvider theme={maternaTheme}>
                    <ApolloProvider client={client}>
                        <MainPage/>
                    </ApolloProvider>
                </MuiThemeProvider>
                </ThemeProvider>
            </div>
        </I18nextProvider>
    );
}

function getExternalProviders() {
    if (process.env.REACT_APP_EXTERNALADFS_LIST === undefined) {
        return [];
    }

    const providers = process.env.REACT_APP_EXTERNALADFS_LIST
        .split(",")
        .filter(o => o.trim().length > 0)
        .map(o => {
            const x = o.split("/")
            return {
                name: x[0],
                id: x[1],
                hidden: process.env.REACT_APP_EXTERNALADFS_HIDDEN?.trim() === "true" ?? false
            }
        });

    return providers;
}

// @ts-ignore
export default withLogin({
    adminsOnly: false,
    requireAcl: false,
    locale: i18next.language.substring(0,2),
    cognitoUrl: "https://" + awsExports.oauth.domain,
    clientId: awsExports.aws_user_pools_web_client_id,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    externalAdfsProviders: getExternalProviders(),
    cookieStorage,
})(App);
