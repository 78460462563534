import React, {useEffect, useState} from "react";
import {Building, RoomUtilization, SeatUsageDataResponse, SeatUsageDataSince, SeatUsageRecord} from "../../API";
import {SelectItem, SelectorOptions} from "../../Utils/MeetingRoomBookingSelectorsUtil";
import {useTranslation} from "react-i18next";
import GenericSelectorComponent from "../SelectorComponents/GenericSelectorComponent";
import {Box} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {Button} from "@material-ui/core";
import {useSeatUsageData} from "../../hooks/usageData/useSeatUsageData";
import {useBuildingList} from "../../hooks/useBuildingList";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {useRoomList} from "../../hooks/useRoomList";
import {useNeighborhoodList} from "../../hooks/useNeighborhoodList";
import dayjs, {Dayjs} from "dayjs";
import moment from "moment";
import uiElementMeasures from "../../styles/inputElementMeasures";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

export interface ReportingFilterProps {
    setFilteredData: (value: ReportingFilteredData[]) => void
}

export interface ReportingFilteredData {
    date: string;
    bookableSeats: number;
    bookedSeats: number;

}

const ReportingFilter: React.FC<ReportingFilterProps> = (props) => {
    let {setFilteredData} = props

    const {t} = useTranslation();
    const roomPlanInitialData = t("reporting-dialog-filter-all-rooms")
    const allNeighborhoodData = t("reporting-dialog-filter-all-neighborhood")
    const noNeighborhoodData = t("reporting-dialog-filter-no-neighborhood")

    const [selectedBuilding, setSelectedBuilding] = useState<string | undefined>(undefined);
    const [selectedRoomPlan, setSelectedRoomPlan] = useState<string | undefined>(undefined);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<string | undefined>(undefined);
    const [selectedAssignedSeatsIndex, setSelectedAssignedSeatsIndex] = useState<number | undefined>(undefined);
    const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(dayjs(moment().subtract(1, 'month').toDate()));
    const [dateUntil, setDateUntil] = React.useState<Dayjs | null>(dayjs(moment().subtract(1, 'day').toDate()));
    const [showDateError, setShowDateError] = useState<boolean>(false);
    const [reportDataAvailableDate, setReportDataAvailableDate] = useState<string | undefined>('');
    const [reportingData, setReportingData] = useState<SeatUsageRecord[]>([]);
    const [dataSince, setDataSince] = useState<SeatUsageDataSince | undefined>(undefined)

    const {isNoFullscreen} = useDeviceMediaType();
    const [listOfManagedBuildings, setListOfManagedBuildings] = useState<Building[]>([]);
    const {idOfDefaultBuilding, currentUser} = useMainApplicationContext();
    const {buildingList} = useBuildingList();
    const {hasManagementForBuilding} = usePermissionHelper();
    const {rooms} = useRoomList();
    const [allNeighborhoodsOfSelectedRoom, refetchNeighborhoods] = useNeighborhoodList(selectedRoomPlan, false)

    const {getData} = useSeatUsageData();

    useEffect(() => {
        setListOfManagedBuildings(currentUser.isAdmin ? buildingList : buildingList.filter(building => hasManagementForBuilding(building)))
    }, [buildingList]);

    useEffect(() => {
        setSelectedBuilding(idOfDefaultBuilding ?? getBuildingOptions().sort(optionsSorterByLabel)[0].element) // back to idOfDefaultBuilding
        setSelectedAssignedSeatsIndex(0);
    }, [listOfManagedBuildings, idOfDefaultBuilding]);

    useEffect(() => {
        if (dateFrom && dateUntil && dateFrom > dateUntil) {
            setShowDateError(true)
        } else {
            setShowDateError(false)
            if (selectedBuilding) {
                const fetchData = async () => {
                    if (dateFrom && dateUntil) {
                        const data: SeatUsageDataResponse = await getData(selectedBuilding, dateFrom?.format('YYYY-MM-DD'), dateUntil?.format('YYYY-MM-DD'))
                        setReportingData(data.items);
                        setDataSince(data.dataSince);
                        setReportDataAvailableDate(data.dataSince.dataSince ?? undefined);
                    }
                }
                fetchData().catch(error => {
                    console.error('reportingFilter error get reporting data')
                })

            }
            setSelectedRoomPlan(roomPlanInitialData)
            setSelectedNeighborhood(undefined)
        }
    }, [selectedBuilding, dateFrom, dateUntil]);

    useEffect(() => {
        setSelectedNeighborhood(undefined)
        refetchNeighborhoods();
        if (selectedRoomPlan === roomPlanInitialData) {
            setReportDataAvailableDate(dataSince?.dataSince ?? undefined);
        } else {
            setReportDataAvailableDate(dataSince?.roomsDataSince.filter(roomData => roomData.roomId === selectedRoomPlan)[0]?.dataSince ?? undefined);
            setSelectedNeighborhood(allNeighborhoodData);
        }
    }, [selectedRoomPlan]);

    useEffect(() => {
        if (selectedNeighborhood === allNeighborhoodData) {
            // by room or newest neighborhood?
            setReportDataAvailableDate(dataSince?.roomsDataSince.filter(roomData => roomData.roomId === selectedRoomPlan)[0]?.dataSince ?? undefined);
        } else if (selectedNeighborhood === noNeighborhoodData) {
            // one more time newest?
            setReportDataAvailableDate(dataSince?.roomsDataSince.filter(
                roomData => roomData.roomId === selectedRoomPlan)[0]?.neighborhoodsDataSince.filter(
                nbHood => nbHood.neighborhoodId === '')[0]?.dataSince ?? undefined);
        } else {
            setReportDataAvailableDate(dataSince?.roomsDataSince.filter(
                roomData => roomData.roomId === selectedRoomPlan)[0]?.neighborhoodsDataSince.filter(
                nbHood => nbHood.neighborhoodId === selectedNeighborhood)[0]?.dataSince ?? undefined);
        }
    }, [selectedNeighborhood])

    // if order will be changed need to change indexes in handleFilterData
    const assignedSeatsOptions: SelectorOptions<string> = {
        options: [
            {
                // index 0
                label: t('reporting-filter-assigned-seats-dont-count'),
                element: [t('reporting-filter-assigned-seats-dont-count')]
            },
            {
                // index 1
                label: t('reporting-filter-assigned-seats-count-as-booked'),
                element: [t('reporting-filter-assigned-seats-count-as-booked')]
            },
            {
                // index 2
                label: t('reporting-filter-assigned-seats-count-as-not-booked'),
                element: [t('reporting-filter-assigned-seats-count-as-not-booked')]
            },
        ],
        defaultSelection: {
            label: t('reporting-filter-assigned-seats-dont-count'),
            element: [t('reporting-filter-assigned-seats-dont-count')]
        },
        defaultSelectionIndex: 0
    };

    const optionsSorterByLabel = (a: SelectItem<string>, b: SelectItem<string>) => {
        return a.label.localeCompare(b.label);
    };

    function onSelectedAssignedSeats(assignedSeats: string[], index?: number) {
        setSelectedAssignedSeatsIndex(index);
    }

    function getBuildingOptions(): SelectItem<string>[] {
        // mapping names of current buildings into labels
        return listOfManagedBuildings.map(building => {
            return {element: building.buildingId, label: building.buildingName}
        })
    }

    function getBuildingIndex(selectedBuilding: string | undefined) {
        if (selectedBuilding === undefined || listOfManagedBuildings.length === 0) return '';
        return listOfManagedBuildings.findIndex(building => building.buildingId === selectedBuilding);
    }

    function getRoomPlanOptions(): SelectItem<string>[] {
        let options = reportingData.filter(building => building.buildingId === selectedBuilding)[0]?.roomData?.map(room => {
            return {
                element: room?.roomId ?? '',
                // mapping names of current roomPlans into labels
                label: rooms.filter(value => value.roomId === room?.roomId)[0]?.name ?? room?.roomId
            };
        }) ?? [];
        // add default option to first place in dropdown
        options.unshift({element: roomPlanInitialData, label: roomPlanInitialData})
        return options;
    }

    function getRoomIndex(selectedRoom: string | undefined) {
        if (selectedRoom === undefined || selectedRoom === roomPlanInitialData) return 0;
        const rooms: RoomUtilization[] = reportingData?.filter(building => building.buildingId === selectedBuilding)[0]?.roomData
        if (!rooms || rooms?.length === 0) return '';
        const index = rooms?.findIndex(
            room => room?.roomId === selectedRoom) ?? 0;
        // index +1 because of first default option
        return index + 1;

    }

    function getNeighborhoodOptions(): SelectItem<string>[] {
        let options: SelectItem<string>[] = [];
        const room = reportingData?.filter(building => building.buildingId === selectedBuilding)[0]?.roomData?.filter(room => room?.roomId == selectedRoomPlan)[0];
        if (room && ((room.neighborhoodData?.length ?? 0) > 0)) {
            options = room.neighborhoodData?.map(nbhood => {
                return {
                    element: nbhood?.neighborhoodId,
                    label: allNeighborhoodsOfSelectedRoom.filter(value => value.neighborhoodId === nbhood?.neighborhoodId)[0]?.name ?? nbhood?.neighborhoodId
                } as SelectItem<string>
            }) ?? []

            // add default options to first two places in dropdown
            options.unshift({element: allNeighborhoodData, label: allNeighborhoodData},
                {element: noNeighborhoodData, label: noNeighborhoodData})
        }
        return options;
    }

    function getNeighborhoodndex(selectedNeighborhood: string | undefined) {
        if (selectedNeighborhood === undefined || selectedRoomPlan === roomPlanInitialData) return '';
        const nbhoods = reportingData.filter(building => building.buildingId === selectedBuilding)[0]?.roomData?.filter(room => room?.roomId === selectedRoomPlan)[0]?.neighborhoodData
        if (!nbhoods || nbhoods.length === 0) return '';
        if (selectedNeighborhood === allNeighborhoodData) return 0;
        if (selectedNeighborhood === noNeighborhoodData) return 1;

        const index = nbhoods?.findIndex(
            nbhood => nbhood?.neighborhoodId === selectedNeighborhood) ?? 0;
        // index +2 because of first 2 default options
        return index + 2;
    }

    function arrayAttributeAddition(array: any[], attribure: string, addReserved: boolean = false): number {
        return array.reduce((counter, obj) => {
            const reserved = addReserved ? (obj['reserved'] as number) : 0
            counter += obj ? (obj[attribure] as number) + reserved : 0;
            return counter;
        }, 0);
    }

    function createFilteredObject(object: any, date: string): ReportingFilteredData {
        // based on selectedAssignedSeatsIndex we count reserved seats some way
        return {
            date: date,
            bookedSeats: selectedAssignedSeatsIndex === 1 ? object?.reserved + object?.booked : object?.booked,
            bookableSeats: selectedAssignedSeatsIndex !== 0 ? object?.reserved + object?.bookable : object?.bookable
        }
    }

    function handleFilterData() {
        let response: ReportingFilteredData[] = [];
        if (selectedBuilding) {
            reportingData.filter(building => building.buildingId === selectedBuilding).forEach(building => {
                    if (selectedRoomPlan === roomPlanInitialData) {
                        response.push({
                            date: building.date,
                            bookedSeats: arrayAttributeAddition(building.roomData ?? [], 'booked', selectedAssignedSeatsIndex === 1) ?? 0,
                            bookableSeats: arrayAttributeAddition(building.roomData ?? [], 'bookable', selectedAssignedSeatsIndex !== 0) ?? 0
                        })
                    } else {
                        const rooms = building.roomData?.filter(room => room?.roomId === selectedRoomPlan) ?? []
                        if (rooms && selectedNeighborhood) {
                            rooms.forEach(item => {
                                if (selectedNeighborhood === allNeighborhoodData) {
                                    response.push({
                                        date: building.date,
                                        bookedSeats: arrayAttributeAddition(item?.neighborhoodData ?? [], 'booked', selectedAssignedSeatsIndex === 1) ?? 0,
                                        bookableSeats: arrayAttributeAddition(item?.neighborhoodData ?? [], 'bookable', selectedAssignedSeatsIndex !== 0) ?? 0
                                    })
                                }
                                if (selectedNeighborhood === noNeighborhoodData) {
                                    const noNeighborhoods = item?.neighborhoodData?.filter(nbHood => !nbHood?.neighborhoodId)
                                    response.push({
                                        date: building.date,
                                        bookedSeats: arrayAttributeAddition(noNeighborhoods ?? [], 'booked', selectedAssignedSeatsIndex === 1) ?? 0,
                                        bookableSeats: arrayAttributeAddition(noNeighborhoods ?? [], 'bookable', selectedAssignedSeatsIndex !== 0) ?? 0
                                    })

                                } else {
                                    const nbhoods =
                                        item?.neighborhoodData?.filter(nbHood => nbHood?.neighborhoodId === selectedNeighborhood) ?? []
                                    nbhoods.forEach(nb =>
                                        response.push(createFilteredObject(nb, building.date))
                                    )
                                }
                            })
                        } else {
                            rooms.forEach(room => {
                                response.push(createFilteredObject(room, building.date))
                            })
                        }
                    }
                }
            )
            setFilteredData(response);
        }
    }

    function getBuildingAndRoomSelectors() {
        return (
            <>
                <GenericSelectorComponent disabled={false}
                                          labelText={t("reporting-filter-building-label")}
                                          chooseFrom={getBuildingOptions()}
                                          selectedIndex={getBuildingIndex(selectedBuilding)}
                                          setSelected={setSelectedBuilding}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}
                                          dataTestId="reporting-select-building"
                />
                <GenericSelectorComponent disabled={false}
                                          labelText={t("reporting-filter-room-label")}
                                          chooseFrom={getRoomPlanOptions()}
                                          selectedIndex={getRoomIndex(selectedRoomPlan)}
                                          setSelected={setSelectedRoomPlan}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}
                                          dataTestId="reporting-select-room"
                />
            </>
        );
    }

    function getNeighborhoodsAndAssignetSeatsSelectors() {
        return (
            <>
                <GenericSelectorComponent disabled={selectedRoomPlan === roomPlanInitialData}
                                          labelText={t("reporting-filter-neighborhood-label")}
                                          chooseFrom={getNeighborhoodOptions()}
                                          selectedIndex={getNeighborhoodndex(selectedNeighborhood)}
                                          setSelected={setSelectedNeighborhood}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}
                                          dataTestId="reporting-select-neighborhood"
                />
                <GenericSelectorComponent disabled={false} labelText={t("reporting-filter-assigned-seats-label")}
                                          chooseFrom={assignedSeatsOptions.options}
                                          selectedIndex={selectedAssignedSeatsIndex ?? assignedSeatsOptions.defaultSelectionIndex}
                                          setSelected={onSelectedAssignedSeats}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}
                                          dataTestId="reporting-select-assigned-seats"/>
            </>
        );
    }

    function getDatePickers(wholeRow: boolean = false) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                <DatePicker sx={{width: wholeRow ? "50%" : "20%"}} label={t("reporting-filter-date-from-label")}
                            value={dateFrom}
                            onChange={(newValue => setDateFrom(newValue))}/>
                <DatePicker sx={{width: wholeRow ? "50%" : "20%"}} label={t("reporting-filter-date-until-label")}
                            value={dateUntil}
                            onChange={(newValue => setDateUntil(newValue))}/>
            </LocalizationProvider>
        );
    }

    function getDateAvailableAndGenerateButton() {
        return (
            <>
                <Box marginBottom={2}>
                    {t('reporting-filter-data-available-label')} {dayjs(reportDataAvailableDate).format('DD.MM.YYYY')}
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={showDateError}
                        onClick={() => handleFilterData()}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"reporting-filter-data-btn"}
                    >
                        {t("reporting-filter-button-text")}
                    </Button>
                </Box>
            </>
        );
    }

    function renderFilterForDesktop() {
        return (
            <>
                <Box sx={{
                    display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                    marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                }}>
                    {getBuildingAndRoomSelectors()}
                    {getNeighborhoodsAndAssignetSeatsSelectors()}
                    {getDatePickers()}
                </Box>
                {showDateError &&
                    <Box sx={{
                        width: "100%",
                        marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                    }}>
                        <Alert severity="error">
                            <AlertTitle className={"alertTitleStyle"}>{t("reporting-filter-date-error")}</AlertTitle>
                        </Alert>
                    </Box>
                }
                {getDateAvailableAndGenerateButton()}
            </>
        );
    }

    function renderFilterForMobile() {
        return (
            <>
                <Box sx={{
                    display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                    marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                }}>
                    {getBuildingAndRoomSelectors()}

                </Box>
                <Box sx={{
                    display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                    marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                }}>
                    {getNeighborhoodsAndAssignetSeatsSelectors()}
                </Box>

                {showDateError &&
                    <Box sx={{
                        width: "100%",
                        marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                    }}>
                        <Alert severity="error">
                            <AlertTitle className={"alertTitleStyle"}>{t("reporting-filter-date-error")}</AlertTitle>
                        </Alert>
                    </Box>
                }
                <Box sx={{
                    display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                    marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                }}>
                    {getDatePickers(true)}
                </Box>
                {getDateAvailableAndGenerateButton()}
            </>
        );
    }

    return isNoFullscreen ? renderFilterForMobile() : renderFilterForDesktop();
}


export default ReportingFilter;
