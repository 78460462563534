import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import ReportingFilter, {ReportingFilteredData} from "./ReportingFilter";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import dayjs from "dayjs";
import uiElementMeasures from "../../styles/inputElementMeasures";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export interface ReportingDialogProps {
    showReportingDialog: boolean
    setShowReportingDialog: (value: boolean) => void
}

interface CsvHeader {
    dateHeader: string
    bookableSeatsHeader: string
    bookedSeatsHeader: string
    utilizationPercentageHeader: string
}

enum Locale {
    DE = 'DE',
    EN = 'EN',
}

const percentFormattingOptions = {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2};

// TODO move to util file
function createCsvFileContentString(header: CsvHeader, data: ReportingFilteredData[], locale: Locale) {
    const csvDelimiter = locale === Locale.DE ? ";" : ",";
    const headerRow = [header.dateHeader, header.bookedSeatsHeader, header.bookableSeatsHeader, header.utilizationPercentageHeader];
    const dataRows = data.map(item => {
        const percentage = item.bookedSeats / item.bookableSeats;
        const numberFormattingLocale = locale === Locale.DE ? 'de-DE' : 'en-US';
        const percentageText = new Intl.NumberFormat(numberFormattingLocale, percentFormattingOptions).format(percentage).replace(/\u00A0/g, ''); //remove non breaking space for german format TODO maybe improve
        return [item.date, item.bookedSeats.toString(), item.bookableSeats.toString(), percentageText]
    });
    const rows = [headerRow, ...dataRows];
    return rows.map(row => row.join(csvDelimiter))
        .join("\n");
}

const ReportingDialog: React.FC<ReportingDialogProps> = (props) => {
    let {showReportingDialog, setShowReportingDialog} = props
    const [filteredData, setFilteredData] = useState<ReportingFilteredData[]>([]);

    const {t, i18n} = useTranslation();
    const locale = i18n.language.startsWith("de") ?  Locale.DE : Locale.EN;
    const csvHeader: CsvHeader = {
        dateHeader: t('reporting-dat-label'),
        bookableSeatsHeader: t('reporting-bookable-seats-label'),
        bookedSeatsHeader: t('reporting-booked-seats-label'),
        utilizationPercentageHeader: t('reporting-utilization-label')
    }

    function downloadCsv() {
        const csvData = new Blob([createCsvFile()], {type: 'text/csv'});
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `reportingTwoGether.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function createCsvFile(){
        return createCsvFileContentString(csvHeader, filteredData, locale);
    }

    function tooltipTextCreator(index: number): string [] {
        return [`${t('reporting-utilization-label')}: ${((filteredData[index].bookedSeats / filteredData[index].bookableSeats) * 100).toFixed(2)} %`,
            `${t('reporting-dat-label')}: ${dayjs(filteredData[index].date).format('DD.MM.YYYY')}`,
            `${t('reporting-bookable-seats-label')}: ${filteredData[index].bookableSeats}`,
            `${t('reporting-booked-seats-label')}: ${filteredData[index].bookedSeats}`]
    }

    const customTooltip = (context: any) => {
        return context.dataIndex ? tooltipTextCreator(context.dataIndex) : '';
    };

    const options = {
        responsive: true,
        aspectRatio: 4,
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => '',
                    label: customTooltip,
                }
            }
        },
        scales: {
            y: {
                min: 0,
                max: 100,
            }
        }
    };

    const data = {
        labels: filteredData.map(item => dayjs(item.date).format('DD.MM.YYYY')),
        datasets: [
            {
                label: t('reporting-utilization-label'),
                data: filteredData.map(item => ((item.bookedSeats / item.bookableSeats) * 100).toFixed(2)),
                borderColor: 'black',
                backgroundColor: 'black',
            },
        ],
    };

    return (
        <Dialog maxWidth={false} fullWidth={true}  open={showReportingDialog}>
            <DialogTitle>
                {t("reporting-dialog-title")}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <ReportingFilter setFilteredData={setFilteredData}></ReportingFilter>
                    <div style={{marginTop: '16px'}}>
                    {filteredData.length > 0 && <Line options={options} data={data} />}
                    </div>
                </DialogContent>
                <DialogActions style={{display: "flex", justifyContent: "end", gap: uiElementMeasures.marginBetweenElementsInColumn}}>
                    <Box style={{ flexGrow: 1, flexBasis: '33.33%', margin: 0}}></Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexGrow: 1, flexBasis: '33.33%', margin: 0}}>
                        <Button
                            disabled={filteredData.length === 0}
                            onClick={() => downloadCsv()}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"reporting-download-csv-btn"}
                        >
                            {t("reporting-dialog-download-csv-button-text")}
                        </Button>
                    </Box>
                    <Box style={{ flexGrow: 1, flexBasis: '33.33%', display: 'flex', justifyContent: 'flex-end', margin: 0}}>
                        <Button
                            onClick={() => setShowReportingDialog(false)}
                            color={"primary"}
                            variant={"contained"}
                            startIcon={<Sit2GetherCloseIcon/>}
                            data-testid={"reporting-close-btn"}
                        >
                            {t("reporting-close-dialog-button-text")}
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>)
}

export default ReportingDialog;
