/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onChangeRoomList = /* GraphQL */ `subscription OnChangeRoomList {
  onChangeRoomList {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeRoomListSubscriptionVariables,
  APITypes.OnChangeRoomListSubscription
>;
export const onChangeSeatBooking = /* GraphQL */ `subscription OnChangeSeatBooking {
  onChangeSeatBooking {
    response
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeSeatBookingSubscriptionVariables,
  APITypes.OnChangeSeatBookingSubscription
>;
export const onChangeMeetingRoomBooking = /* GraphQL */ `subscription OnChangeMeetingRoomBooking {
  onChangeMeetingRoomBooking {
    response
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeMeetingRoomBookingSubscriptionVariables,
  APITypes.OnChangeMeetingRoomBookingSubscription
>;
export const onChangeBuildings = /* GraphQL */ `subscription OnChangeBuildings {
  onChangeBuildings {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeBuildingsSubscriptionVariables,
  APITypes.OnChangeBuildingsSubscription
>;
export const onChangeInventories = /* GraphQL */ `subscription OnChangeInventories {
  onChangeInventories {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeInventoriesSubscriptionVariables,
  APITypes.OnChangeInventoriesSubscription
>;
export const onChangeHintsByRoom = /* GraphQL */ `subscription OnChangeHintsByRoom($roomId: ID!) {
  onChangeHintsByRoom(roomId: $roomId) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeHintsByRoomSubscriptionVariables,
  APITypes.OnChangeHintsByRoomSubscription
>;
export const onCreateDeleteNeighborhoods = /* GraphQL */ `subscription OnCreateDeleteNeighborhoods {
  onCreateDeleteNeighborhoods {
    response
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDeleteNeighborhoodsSubscriptionVariables,
  APITypes.OnCreateDeleteNeighborhoodsSubscription
>;
export const onUpdateSecureMeetingRoom = /* GraphQL */ `subscription OnUpdateSecureMeetingRoom {
  onUpdateSecureMeetingRoom {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSecureMeetingRoomSubscriptionVariables,
  APITypes.OnUpdateSecureMeetingRoomSubscription
>;
export const onUpdateRestrictedNeighborhoods = /* GraphQL */ `subscription OnUpdateRestrictedNeighborhoods {
  onUpdateRestrictedNeighborhoods {
    statusCode
    message
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRestrictedNeighborhoodsSubscriptionVariables,
  APITypes.OnUpdateRestrictedNeighborhoodsSubscription
>;
export const onCreateBuilding = /* GraphQL */ `subscription OnCreateBuilding($filter: ModelSubscriptionBuildingFilterInput) {
  onCreateBuilding(filter: $filter) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBuildingSubscriptionVariables,
  APITypes.OnCreateBuildingSubscription
>;
export const onUpdateBuilding = /* GraphQL */ `subscription OnUpdateBuilding($filter: ModelSubscriptionBuildingFilterInput) {
  onUpdateBuilding(filter: $filter) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBuildingSubscriptionVariables,
  APITypes.OnUpdateBuildingSubscription
>;
export const onDeleteBuilding = /* GraphQL */ `subscription OnDeleteBuilding($filter: ModelSubscriptionBuildingFilterInput) {
  onDeleteBuilding(filter: $filter) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBuildingSubscriptionVariables,
  APITypes.OnDeleteBuildingSubscription
>;
export const onCreateInventory = /* GraphQL */ `subscription OnCreateInventory($filter: ModelSubscriptionInventoryFilterInput) {
  onCreateInventory(filter: $filter) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInventorySubscriptionVariables,
  APITypes.OnCreateInventorySubscription
>;
export const onUpdateInventory = /* GraphQL */ `subscription OnUpdateInventory($filter: ModelSubscriptionInventoryFilterInput) {
  onUpdateInventory(filter: $filter) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInventorySubscriptionVariables,
  APITypes.OnUpdateInventorySubscription
>;
export const onDeleteInventory = /* GraphQL */ `subscription OnDeleteInventory($filter: ModelSubscriptionInventoryFilterInput) {
  onDeleteInventory(filter: $filter) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInventorySubscriptionVariables,
  APITypes.OnDeleteInventorySubscription
>;
export const onCreateMeetingRoomEquipment = /* GraphQL */ `subscription OnCreateMeetingRoomEquipment(
  $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
) {
  onCreateMeetingRoomEquipment(filter: $filter) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMeetingRoomEquipmentSubscriptionVariables,
  APITypes.OnCreateMeetingRoomEquipmentSubscription
>;
export const onUpdateMeetingRoomEquipment = /* GraphQL */ `subscription OnUpdateMeetingRoomEquipment(
  $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
) {
  onUpdateMeetingRoomEquipment(filter: $filter) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMeetingRoomEquipmentSubscriptionVariables,
  APITypes.OnUpdateMeetingRoomEquipmentSubscription
>;
export const onDeleteMeetingRoomEquipment = /* GraphQL */ `subscription OnDeleteMeetingRoomEquipment(
  $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
) {
  onDeleteMeetingRoomEquipment(filter: $filter) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMeetingRoomEquipmentSubscriptionVariables,
  APITypes.OnDeleteMeetingRoomEquipmentSubscription
>;
export const onCreateMeetingRoomSeatConfig = /* GraphQL */ `subscription OnCreateMeetingRoomSeatConfig(
  $filter: ModelSubscriptionMeetingRoomSeatConfigFilterInput
) {
  onCreateMeetingRoomSeatConfig(filter: $filter) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMeetingRoomSeatConfigSubscriptionVariables,
  APITypes.OnCreateMeetingRoomSeatConfigSubscription
>;
export const onUpdateMeetingRoomSeatConfig = /* GraphQL */ `subscription OnUpdateMeetingRoomSeatConfig(
  $filter: ModelSubscriptionMeetingRoomSeatConfigFilterInput
) {
  onUpdateMeetingRoomSeatConfig(filter: $filter) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMeetingRoomSeatConfigSubscriptionVariables,
  APITypes.OnUpdateMeetingRoomSeatConfigSubscription
>;
export const onDeleteMeetingRoomSeatConfig = /* GraphQL */ `subscription OnDeleteMeetingRoomSeatConfig(
  $filter: ModelSubscriptionMeetingRoomSeatConfigFilterInput
) {
  onDeleteMeetingRoomSeatConfig(filter: $filter) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMeetingRoomSeatConfigSubscriptionVariables,
  APITypes.OnDeleteMeetingRoomSeatConfigSubscription
>;
export const onCreateSeatConfig = /* GraphQL */ `subscription OnCreateSeatConfig(
  $filter: ModelSubscriptionSeatConfigFilterInput
) {
  onCreateSeatConfig(filter: $filter) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSeatConfigSubscriptionVariables,
  APITypes.OnCreateSeatConfigSubscription
>;
export const onUpdateSeatConfig = /* GraphQL */ `subscription OnUpdateSeatConfig(
  $filter: ModelSubscriptionSeatConfigFilterInput
) {
  onUpdateSeatConfig(filter: $filter) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSeatConfigSubscriptionVariables,
  APITypes.OnUpdateSeatConfigSubscription
>;
export const onDeleteSeatConfig = /* GraphQL */ `subscription OnDeleteSeatConfig(
  $filter: ModelSubscriptionSeatConfigFilterInput
) {
  onDeleteSeatConfig(filter: $filter) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSeatConfigSubscriptionVariables,
  APITypes.OnDeleteSeatConfigSubscription
>;
export const onCreateBookingConfig = /* GraphQL */ `subscription OnCreateBookingConfig(
  $filter: ModelSubscriptionBookingConfigFilterInput
) {
  onCreateBookingConfig(filter: $filter) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBookingConfigSubscriptionVariables,
  APITypes.OnCreateBookingConfigSubscription
>;
export const onUpdateBookingConfig = /* GraphQL */ `subscription OnUpdateBookingConfig(
  $filter: ModelSubscriptionBookingConfigFilterInput
) {
  onUpdateBookingConfig(filter: $filter) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBookingConfigSubscriptionVariables,
  APITypes.OnUpdateBookingConfigSubscription
>;
export const onDeleteBookingConfig = /* GraphQL */ `subscription OnDeleteBookingConfig(
  $filter: ModelSubscriptionBookingConfigFilterInput
) {
  onDeleteBookingConfig(filter: $filter) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBookingConfigSubscriptionVariables,
  APITypes.OnDeleteBookingConfigSubscription
>;
export const onCreateMailConfig = /* GraphQL */ `subscription OnCreateMailConfig(
  $filter: ModelSubscriptionMailConfigFilterInput
  $userId: String
) {
  onCreateMailConfig(filter: $filter, userId: $userId) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMailConfigSubscriptionVariables,
  APITypes.OnCreateMailConfigSubscription
>;
export const onUpdateMailConfig = /* GraphQL */ `subscription OnUpdateMailConfig(
  $filter: ModelSubscriptionMailConfigFilterInput
  $userId: String
) {
  onUpdateMailConfig(filter: $filter, userId: $userId) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMailConfigSubscriptionVariables,
  APITypes.OnUpdateMailConfigSubscription
>;
export const onDeleteMailConfig = /* GraphQL */ `subscription OnDeleteMailConfig(
  $filter: ModelSubscriptionMailConfigFilterInput
  $userId: String
) {
  onDeleteMailConfig(filter: $filter, userId: $userId) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMailConfigSubscriptionVariables,
  APITypes.OnDeleteMailConfigSubscription
>;
export const onCreateDefaultRoomConfig = /* GraphQL */ `subscription OnCreateDefaultRoomConfig(
  $filter: ModelSubscriptionDefaultRoomConfigFilterInput
  $userId: String
) {
  onCreateDefaultRoomConfig(filter: $filter, userId: $userId) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDefaultRoomConfigSubscriptionVariables,
  APITypes.OnCreateDefaultRoomConfigSubscription
>;
export const onUpdateDefaultRoomConfig = /* GraphQL */ `subscription OnUpdateDefaultRoomConfig(
  $filter: ModelSubscriptionDefaultRoomConfigFilterInput
  $userId: String
) {
  onUpdateDefaultRoomConfig(filter: $filter, userId: $userId) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDefaultRoomConfigSubscriptionVariables,
  APITypes.OnUpdateDefaultRoomConfigSubscription
>;
export const onDeleteDefaultRoomConfig = /* GraphQL */ `subscription OnDeleteDefaultRoomConfig(
  $filter: ModelSubscriptionDefaultRoomConfigFilterInput
  $userId: String
) {
  onDeleteDefaultRoomConfig(filter: $filter, userId: $userId) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDefaultRoomConfigSubscriptionVariables,
  APITypes.OnDeleteDefaultRoomConfigSubscription
>;
export const onCreateUserSettings = /* GraphQL */ `subscription OnCreateUserSettings(
  $filter: ModelSubscriptionUserSettingsFilterInput
  $userId: String
) {
  onCreateUserSettings(filter: $filter, userId: $userId) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSettingsSubscriptionVariables,
  APITypes.OnCreateUserSettingsSubscription
>;
export const onUpdateUserSettings = /* GraphQL */ `subscription OnUpdateUserSettings(
  $filter: ModelSubscriptionUserSettingsFilterInput
  $userId: String
) {
  onUpdateUserSettings(filter: $filter, userId: $userId) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSettingsSubscriptionVariables,
  APITypes.OnUpdateUserSettingsSubscription
>;
export const onDeleteUserSettings = /* GraphQL */ `subscription OnDeleteUserSettings(
  $filter: ModelSubscriptionUserSettingsFilterInput
  $userId: String
) {
  onDeleteUserSettings(filter: $filter, userId: $userId) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSettingsSubscriptionVariables,
  APITypes.OnDeleteUserSettingsSubscription
>;
export const onCreateInfoText = /* GraphQL */ `subscription OnCreateInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
  onCreateInfoText(filter: $filter) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInfoTextSubscriptionVariables,
  APITypes.OnCreateInfoTextSubscription
>;
export const onUpdateInfoText = /* GraphQL */ `subscription OnUpdateInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
  onUpdateInfoText(filter: $filter) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInfoTextSubscriptionVariables,
  APITypes.OnUpdateInfoTextSubscription
>;
export const onDeleteInfoText = /* GraphQL */ `subscription OnDeleteInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
  onDeleteInfoText(filter: $filter) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInfoTextSubscriptionVariables,
  APITypes.OnDeleteInfoTextSubscription
>;
