/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const publishRoomAccessChange = /* GraphQL */ `mutation PublishRoomAccessChange($roomId: ID!) {
  publishRoomAccessChange(roomId: $roomId)
}
` as GeneratedMutation<
  APITypes.PublishRoomAccessChangeMutationVariables,
  APITypes.PublishRoomAccessChangeMutation
>;
export const roomPlanSecureUploadActions = /* GraphQL */ `mutation RoomPlanSecureUploadActions($input: RoomPlanUploadActionsInput) {
  roomPlanSecureUploadActions(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.RoomPlanSecureUploadActionsMutationVariables,
  APITypes.RoomPlanSecureUploadActionsMutation
>;
export const createBookings = /* GraphQL */ `mutation CreateBookings($input: [CreateBookingInput]) {
  createBookings(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingsMutationVariables,
  APITypes.CreateBookingsMutation
>;
export const updateSecureSeatBooking = /* GraphQL */ `mutation UpdateSecureSeatBooking($input: UpdateSeatBookingInput) {
  updateSecureSeatBooking(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureSeatBookingMutationVariables,
  APITypes.UpdateSecureSeatBookingMutation
>;
export const deleteSecureSeatBooking = /* GraphQL */ `mutation DeleteSecureSeatBooking($input: DeleteBookingInput) {
  deleteSecureSeatBooking(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureSeatBookingMutationVariables,
  APITypes.DeleteSecureSeatBookingMutation
>;
export const deleteSecureSeatBookingsByUserId = /* GraphQL */ `mutation DeleteSecureSeatBookingsByUserId($input: DeleteBookingsByUserInput) {
  deleteSecureSeatBookingsByUserId(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureSeatBookingsByUserIdMutationVariables,
  APITypes.DeleteSecureSeatBookingsByUserIdMutation
>;
export const createSecureBuilding = /* GraphQL */ `mutation CreateSecureBuilding($input: CreateBuildingInput) {
  createSecureBuilding(input: $input) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureBuildingMutationVariables,
  APITypes.CreateSecureBuildingMutation
>;
export const updateSecureBuilding = /* GraphQL */ `mutation UpdateSecureBuilding($input: UpdateBuildingInput) {
  updateSecureBuilding(input: $input) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureBuildingMutationVariables,
  APITypes.UpdateSecureBuildingMutation
>;
export const deleteSecureBuilding = /* GraphQL */ `mutation DeleteSecureBuilding($input: DeleteBuildingInput) {
  deleteSecureBuilding(input: $input) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureBuildingMutationVariables,
  APITypes.DeleteSecureBuildingMutation
>;
export const createMeetingRooms = /* GraphQL */ `mutation CreateMeetingRooms($input: [CreateMeetingRoomInput]) {
  createMeetingRooms(input: $input) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomsMutationVariables,
  APITypes.CreateMeetingRoomsMutation
>;
export const updateSecureMeetingRoom = /* GraphQL */ `mutation UpdateSecureMeetingRoom($input: UpdateMeetingRoomInput) {
  updateSecureMeetingRoom(input: $input) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureMeetingRoomMutationVariables,
  APITypes.UpdateSecureMeetingRoomMutation
>;
export const deleteSecureMeetingRoom = /* GraphQL */ `mutation DeleteSecureMeetingRoom($input: DeleteMeetingRoomInput) {
  deleteSecureMeetingRoom(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureMeetingRoomMutationVariables,
  APITypes.DeleteSecureMeetingRoomMutation
>;
export const deleteAllMeetingRoomBookings = /* GraphQL */ `mutation DeleteAllMeetingRoomBookings($meetingRoomId: ID!) {
  deleteAllMeetingRoomBookings(meetingRoomId: $meetingRoomId)
}
` as GeneratedMutation<
  APITypes.DeleteAllMeetingRoomBookingsMutationVariables,
  APITypes.DeleteAllMeetingRoomBookingsMutation
>;
export const createSecureRoom = /* GraphQL */ `mutation CreateSecureRoom($input: CreateRoomInput) {
  createSecureRoom(input: $input) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureRoomMutationVariables,
  APITypes.CreateSecureRoomMutation
>;
export const updateSecureRoom = /* GraphQL */ `mutation UpdateSecureRoom($input: UpdateRoomInput, $updateType: String) {
  updateSecureRoom(input: $input, updateType: $updateType) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureRoomMutationVariables,
  APITypes.UpdateSecureRoomMutation
>;
export const deleteSecureRoom = /* GraphQL */ `mutation DeleteSecureRoom($input: DeleteRoomInput) {
  deleteSecureRoom(input: $input) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureRoomMutationVariables,
  APITypes.DeleteSecureRoomMutation
>;
export const createSecureSeatConfig = /* GraphQL */ `mutation CreateSecureSeatConfig($input: CreateSeatConfig) {
  createSecureSeatConfig(input: $input) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureSeatConfigMutationVariables,
  APITypes.CreateSecureSeatConfigMutation
>;
export const deleteSecureSeatConfig = /* GraphQL */ `mutation DeleteSecureSeatConfig($input: DeleteSeatConfig) {
  deleteSecureSeatConfig(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureSeatConfigMutationVariables,
  APITypes.DeleteSecureSeatConfigMutation
>;
export const createMeetingRoomBookings = /* GraphQL */ `mutation CreateMeetingRoomBookings($input: [CreateMeetingRoomBookingInput]) {
  createMeetingRoomBookings(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomBookingsMutationVariables,
  APITypes.CreateMeetingRoomBookingsMutation
>;
export const updateSecureMeetingRoomBooking = /* GraphQL */ `mutation UpdateSecureMeetingRoomBooking($input: UpdateMeetingRoomBookingInput) {
  updateSecureMeetingRoomBooking(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureMeetingRoomBookingMutationVariables,
  APITypes.UpdateSecureMeetingRoomBookingMutation
>;
export const deleteSecureMeetingRoomBooking = /* GraphQL */ `mutation DeleteSecureMeetingRoomBooking($input: DeleteMeetingRoomBookingInput) {
  deleteSecureMeetingRoomBooking(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureMeetingRoomBookingMutationVariables,
  APITypes.DeleteSecureMeetingRoomBookingMutation
>;
export const updateSeatConfigs = /* GraphQL */ `mutation UpdateSeatConfigs($input: UpdateSeatConfigsInput) {
  updateSeatConfigs(input: $input) {
    message
    cause
  }
}
` as GeneratedMutation<
  APITypes.UpdateSeatConfigsMutationVariables,
  APITypes.UpdateSeatConfigsMutation
>;
export const deleteBookingsBySeatIds = /* GraphQL */ `mutation DeleteBookingsBySeatIds($input: DeleteBookingsBySeatIdsInput) {
  deleteBookingsBySeatIds(input: $input)
}
` as GeneratedMutation<
  APITypes.DeleteBookingsBySeatIdsMutationVariables,
  APITypes.DeleteBookingsBySeatIdsMutation
>;
export const updateRestrictedNeighborhoods = /* GraphQL */ `mutation UpdateRestrictedNeighborhoods(
  $input: UpdateRestrictedNeighborhoodsInput
) {
  updateRestrictedNeighborhoods(input: $input) {
    statusCode
    message
  }
}
` as GeneratedMutation<
  APITypes.UpdateRestrictedNeighborhoodsMutationVariables,
  APITypes.UpdateRestrictedNeighborhoodsMutation
>;
export const createSecureInventory = /* GraphQL */ `mutation CreateSecureInventory($input: InventoryInput) {
  createSecureInventory(input: $input) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureInventoryMutationVariables,
  APITypes.CreateSecureInventoryMutation
>;
export const deleteSecureInventory = /* GraphQL */ `mutation DeleteSecureInventory($input: DeleteInventoryInput) {
  deleteSecureInventory(input: $input) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureInventoryMutationVariables,
  APITypes.DeleteSecureInventoryMutation
>;
export const updateSecureInventory = /* GraphQL */ `mutation UpdateSecureInventory($input: InventoryInput) {
  updateSecureInventory(input: $input) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureInventoryMutationVariables,
  APITypes.UpdateSecureInventoryMutation
>;
export const createSecureNeighborhoods = /* GraphQL */ `mutation CreateSecureNeighborhoods($input: CreateNeighborhoodsInput) {
  createSecureNeighborhoods(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureNeighborhoodsMutationVariables,
  APITypes.CreateSecureNeighborhoodsMutation
>;
export const deleteSecureNeighborhoods = /* GraphQL */ `mutation DeleteSecureNeighborhoods($input: DeleteNeighborhoodsInput) {
  deleteSecureNeighborhoods(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureNeighborhoodsMutationVariables,
  APITypes.DeleteSecureNeighborhoodsMutation
>;
export const createSecureMeetingRoomEquipment = /* GraphQL */ `mutation CreateSecureMeetingRoomEquipment(
  $input: CreateMeetingRoomEquipmentInput
) {
  createSecureMeetingRoomEquipment(input: $input) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureMeetingRoomEquipmentMutationVariables,
  APITypes.CreateSecureMeetingRoomEquipmentMutation
>;
export const updateSecureMeetingRoomEquipment = /* GraphQL */ `mutation UpdateSecureMeetingRoomEquipment(
  $input: UpdateMeetingRoomEquipmentInput
) {
  updateSecureMeetingRoomEquipment(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureMeetingRoomEquipmentMutationVariables,
  APITypes.UpdateSecureMeetingRoomEquipmentMutation
>;
export const deleteSecureMeetingRoomEquipment = /* GraphQL */ `mutation DeleteSecureMeetingRoomEquipment(
  $input: DeleteMeetingRoomEquipmentInput
) {
  deleteSecureMeetingRoomEquipment(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureMeetingRoomEquipmentMutationVariables,
  APITypes.DeleteSecureMeetingRoomEquipmentMutation
>;
export const createSecureMeetingRoomSeatConfig = /* GraphQL */ `mutation CreateSecureMeetingRoomSeatConfig(
  $input: CreateMeetingRoomSeatConfigInput
) {
  createSecureMeetingRoomSeatConfig(input: $input) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSecureMeetingRoomSeatConfigMutationVariables,
  APITypes.CreateSecureMeetingRoomSeatConfigMutation
>;
export const updateSecureMeetingRoomSeatConfig = /* GraphQL */ `mutation UpdateSecureMeetingRoomSeatConfig(
  $input: UpdateMeetingRoomSeatConfigInput
) {
  updateSecureMeetingRoomSeatConfig(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecureMeetingRoomSeatConfigMutationVariables,
  APITypes.UpdateSecureMeetingRoomSeatConfigMutation
>;
export const deleteSecureMeetingRoomSeatConfig = /* GraphQL */ `mutation DeleteSecureMeetingRoomSeatConfig(
  $input: DeleteMeetingRoomSeatConfigInput
) {
  deleteSecureMeetingRoomSeatConfig(input: $input) {
    response
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecureMeetingRoomSeatConfigMutationVariables,
  APITypes.DeleteSecureMeetingRoomSeatConfigMutation
>;
export const createBuilding = /* GraphQL */ `mutation CreateBuilding(
  $input: CreateBuildingInput!
  $condition: ModelBuildingConditionInput
) {
  createBuilding(input: $input, condition: $condition) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateBuildingMutationVariables,
  APITypes.CreateBuildingMutation
>;
export const updateBuilding = /* GraphQL */ `mutation UpdateBuilding(
  $input: UpdateBuildingInput!
  $condition: ModelBuildingConditionInput
) {
  updateBuilding(input: $input, condition: $condition) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuildingMutationVariables,
  APITypes.UpdateBuildingMutation
>;
export const deleteBuilding = /* GraphQL */ `mutation DeleteBuilding(
  $input: DeleteBuildingInput!
  $condition: ModelBuildingConditionInput
) {
  deleteBuilding(input: $input, condition: $condition) {
    buildingId
    buildingName
    rooms {
      nextToken
    }
    roleIds
    buildingNameLowerCased
    seatConfEmail
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuildingMutationVariables,
  APITypes.DeleteBuildingMutation
>;
export const createRoom = /* GraphQL */ `mutation CreateRoom(
  $input: CreateRoomInput!
  $condition: ModelRoomConditionInput
) {
  createRoom(input: $input, condition: $condition) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateRoomMutationVariables,
  APITypes.CreateRoomMutation
>;
export const updateRoom = /* GraphQL */ `mutation UpdateRoom(
  $input: UpdateRoomInput!
  $condition: ModelRoomConditionInput
) {
  updateRoom(input: $input, condition: $condition) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoomMutationVariables,
  APITypes.UpdateRoomMutation
>;
export const deleteRoom = /* GraphQL */ `mutation DeleteRoom(
  $input: DeleteRoomInput!
  $condition: ModelRoomConditionInput
) {
  deleteRoom(input: $input, condition: $condition) {
    roomId
    buildingId
    orgUnitId
    orgUnitAdmin
    name
    nameLowerCased
    roomPlanExisting
    roomPlanId
    roomPlanScaleFactor
    seats {
      nextToken
    }
    isActive
    isTimeActive
    isBookingDisabled
    isDefault
    isPublic
    hasHints
    hasNeighborhood
    floor
    hasMeetingRooms
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoomMutationVariables,
  APITypes.DeleteRoomMutation
>;
export const createMeetingRoom = /* GraphQL */ `mutation CreateMeetingRoom(
  $input: CreateMeetingRoomInput!
  $condition: ModelMeetingRoomConditionInput
) {
  createMeetingRoom(input: $input, condition: $condition) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomMutationVariables,
  APITypes.CreateMeetingRoomMutation
>;
export const updateMeetingRoom = /* GraphQL */ `mutation UpdateMeetingRoom(
  $input: UpdateMeetingRoomInput!
  $condition: ModelMeetingRoomConditionInput
) {
  updateMeetingRoom(input: $input, condition: $condition) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingRoomMutationVariables,
  APITypes.UpdateMeetingRoomMutation
>;
export const deleteMeetingRoom = /* GraphQL */ `mutation DeleteMeetingRoom(
  $input: DeleteMeetingRoomInput!
  $condition: ModelMeetingRoomConditionInput
) {
  deleteMeetingRoom(input: $input, condition: $condition) {
    meetingRoomId
    roomId
    name
    nameLowerCased
    hintId
    type
    capacity
    roleIds
    neighborhoodId
    equipmentIds
    meetingRoomSeatConfIds
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingRoomMutationVariables,
  APITypes.DeleteMeetingRoomMutation
>;
export const createNeighborhood = /* GraphQL */ `mutation CreateNeighborhood(
  $input: CreateNeighborhoodInput!
  $condition: ModelNeighborhoodConditionInput
) {
  createNeighborhood(input: $input, condition: $condition) {
    neighborhoodId
    roomId
    restricted
    roleIds
    name
    adminRoleIds
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateNeighborhoodMutationVariables,
  APITypes.CreateNeighborhoodMutation
>;
export const updateNeighborhood = /* GraphQL */ `mutation UpdateNeighborhood(
  $input: UpdateNeighborhoodInput!
  $condition: ModelNeighborhoodConditionInput
) {
  updateNeighborhood(input: $input, condition: $condition) {
    neighborhoodId
    roomId
    restricted
    roleIds
    name
    adminRoleIds
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateNeighborhoodMutationVariables,
  APITypes.UpdateNeighborhoodMutation
>;
export const deleteNeighborhood = /* GraphQL */ `mutation DeleteNeighborhood(
  $input: DeleteNeighborhoodInput!
  $condition: ModelNeighborhoodConditionInput
) {
  deleteNeighborhood(input: $input, condition: $condition) {
    neighborhoodId
    roomId
    restricted
    roleIds
    name
    adminRoleIds
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteNeighborhoodMutationVariables,
  APITypes.DeleteNeighborhoodMutation
>;
export const createBooking = /* GraphQL */ `mutation CreateBooking(
  $input: CreateBookingInput!
  $condition: ModelBookingConditionInput
) {
  createBooking(input: $input, condition: $condition) {
    bookingId
    date
    seatId
    roomId
    room {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    orgUnitAdmin
    bookingFor
    timeBegin
    timeEnd
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingMutationVariables,
  APITypes.CreateBookingMutation
>;
export const updateBooking = /* GraphQL */ `mutation UpdateBooking(
  $input: UpdateBookingInput!
  $condition: ModelBookingConditionInput
) {
  updateBooking(input: $input, condition: $condition) {
    bookingId
    date
    seatId
    roomId
    room {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    orgUnitAdmin
    bookingFor
    timeBegin
    timeEnd
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingMutationVariables,
  APITypes.UpdateBookingMutation
>;
export const deleteBooking = /* GraphQL */ `mutation DeleteBooking(
  $input: DeleteBookingInput!
  $condition: ModelBookingConditionInput
) {
  deleteBooking(input: $input, condition: $condition) {
    bookingId
    date
    seatId
    roomId
    room {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    orgUnitAdmin
    bookingFor
    timeBegin
    timeEnd
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingMutationVariables,
  APITypes.DeleteBookingMutation
>;
export const createMeetingRoomBooking = /* GraphQL */ `mutation CreateMeetingRoomBooking(
  $input: CreateMeetingRoomBookingInput!
  $condition: ModelMeetingRoomBookingConditionInput
) {
  createMeetingRoomBooking(input: $input, condition: $condition) {
    bookingId
    date
    meetingRoomId
    roomId
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    timeBegin
    timeEnd
    meetingName
    roomCapacity
    participantNumber
    meetingType
    visitors
    meetingRoomSeatConfId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomBookingMutationVariables,
  APITypes.CreateMeetingRoomBookingMutation
>;
export const updateMeetingRoomBooking = /* GraphQL */ `mutation UpdateMeetingRoomBooking(
  $input: UpdateMeetingRoomBookingInput!
  $condition: ModelMeetingRoomBookingConditionInput
) {
  updateMeetingRoomBooking(input: $input, condition: $condition) {
    bookingId
    date
    meetingRoomId
    roomId
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    timeBegin
    timeEnd
    meetingName
    roomCapacity
    participantNumber
    meetingType
    visitors
    meetingRoomSeatConfId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingRoomBookingMutationVariables,
  APITypes.UpdateMeetingRoomBookingMutation
>;
export const deleteMeetingRoomBooking = /* GraphQL */ `mutation DeleteMeetingRoomBooking(
  $input: DeleteMeetingRoomBookingInput!
  $condition: ModelMeetingRoomBookingConditionInput
) {
  deleteMeetingRoomBooking(input: $input, condition: $condition) {
    bookingId
    date
    meetingRoomId
    roomId
    bookerId
    bookerName
    bookerGivenName
    bookerFamilyName
    orgUnitId
    timeBegin
    timeEnd
    meetingName
    roomCapacity
    participantNumber
    meetingType
    visitors
    meetingRoomSeatConfId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingRoomBookingMutationVariables,
  APITypes.DeleteMeetingRoomBookingMutation
>;
export const createInventory = /* GraphQL */ `mutation CreateInventory(
  $input: CreateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  createInventory(input: $input, condition: $condition) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInventoryMutationVariables,
  APITypes.CreateInventoryMutation
>;
export const updateInventory = /* GraphQL */ `mutation UpdateInventory(
  $input: UpdateInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  updateInventory(input: $input, condition: $condition) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInventoryMutationVariables,
  APITypes.UpdateInventoryMutation
>;
export const deleteInventory = /* GraphQL */ `mutation DeleteInventory(
  $input: DeleteInventoryInput!
  $condition: ModelInventoryConditionInput
) {
  deleteInventory(input: $input, condition: $condition) {
    inventoryId
    orgUnitAdmin
    orgUnitId
    type
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInventoryMutationVariables,
  APITypes.DeleteInventoryMutation
>;
export const createMeetingRoomEquipment = /* GraphQL */ `mutation CreateMeetingRoomEquipment(
  $input: CreateMeetingRoomEquipmentInput!
  $condition: ModelMeetingRoomEquipmentConditionInput
) {
  createMeetingRoomEquipment(input: $input, condition: $condition) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomEquipmentMutationVariables,
  APITypes.CreateMeetingRoomEquipmentMutation
>;
export const updateMeetingRoomEquipment = /* GraphQL */ `mutation UpdateMeetingRoomEquipment(
  $input: UpdateMeetingRoomEquipmentInput!
  $condition: ModelMeetingRoomEquipmentConditionInput
) {
  updateMeetingRoomEquipment(input: $input, condition: $condition) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingRoomEquipmentMutationVariables,
  APITypes.UpdateMeetingRoomEquipmentMutation
>;
export const deleteMeetingRoomEquipment = /* GraphQL */ `mutation DeleteMeetingRoomEquipment(
  $input: DeleteMeetingRoomEquipmentInput!
  $condition: ModelMeetingRoomEquipmentConditionInput
) {
  deleteMeetingRoomEquipment(input: $input, condition: $condition) {
    equipmentId
    buildingId
    name
    nameLowerCased
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingRoomEquipmentMutationVariables,
  APITypes.DeleteMeetingRoomEquipmentMutation
>;
export const createMeetingRoomSeatConfig = /* GraphQL */ `mutation CreateMeetingRoomSeatConfig(
  $input: CreateMeetingRoomSeatConfigInput!
  $condition: ModelMeetingRoomSeatConfigConditionInput
) {
  createMeetingRoomSeatConfig(input: $input, condition: $condition) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingRoomSeatConfigMutationVariables,
  APITypes.CreateMeetingRoomSeatConfigMutation
>;
export const updateMeetingRoomSeatConfig = /* GraphQL */ `mutation UpdateMeetingRoomSeatConfig(
  $input: UpdateMeetingRoomSeatConfigInput!
  $condition: ModelMeetingRoomSeatConfigConditionInput
) {
  updateMeetingRoomSeatConfig(input: $input, condition: $condition) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingRoomSeatConfigMutationVariables,
  APITypes.UpdateMeetingRoomSeatConfigMutation
>;
export const deleteMeetingRoomSeatConfig = /* GraphQL */ `mutation DeleteMeetingRoomSeatConfig(
  $input: DeleteMeetingRoomSeatConfigInput!
  $condition: ModelMeetingRoomSeatConfigConditionInput
) {
  deleteMeetingRoomSeatConfig(input: $input, condition: $condition) {
    meetingRoomSeatConfId
    buildingId
    roomId
    meetingRoomId
    name
    imageId
    daysInAdvance
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingRoomSeatConfigMutationVariables,
  APITypes.DeleteMeetingRoomSeatConfigMutation
>;
export const createSeatConfig = /* GraphQL */ `mutation CreateSeatConfig(
  $input: CreateSeatConfigInput!
  $condition: ModelSeatConfigConditionInput
) {
  createSeatConfig(input: $input, condition: $condition) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSeatConfigMutationVariables,
  APITypes.CreateSeatConfigMutation
>;
export const updateSeatConfig = /* GraphQL */ `mutation UpdateSeatConfig(
  $input: UpdateSeatConfigInput!
  $condition: ModelSeatConfigConditionInput
) {
  updateSeatConfig(input: $input, condition: $condition) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSeatConfigMutationVariables,
  APITypes.UpdateSeatConfigMutation
>;
export const deleteSeatConfig = /* GraphQL */ `mutation DeleteSeatConfig(
  $input: DeleteSeatConfigInput!
  $condition: ModelSeatConfigConditionInput
) {
  deleteSeatConfig(input: $input, condition: $condition) {
    seatName
    orgUnitAdmin
    roomId
    seatType
    inventory
    isSeatHeightAdjustable
    owner
    isOwnerRegistered
    neighborhoodId
    isBookable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSeatConfigMutationVariables,
  APITypes.DeleteSeatConfigMutation
>;
export const createBookingConfig = /* GraphQL */ `mutation CreateBookingConfig(
  $input: CreateBookingConfigInput!
  $condition: ModelBookingConfigConditionInput
) {
  createBookingConfig(input: $input, condition: $condition) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingConfigMutationVariables,
  APITypes.CreateBookingConfigMutation
>;
export const updateBookingConfig = /* GraphQL */ `mutation UpdateBookingConfig(
  $input: UpdateBookingConfigInput!
  $condition: ModelBookingConfigConditionInput
) {
  updateBookingConfig(input: $input, condition: $condition) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingConfigMutationVariables,
  APITypes.UpdateBookingConfigMutation
>;
export const deleteBookingConfig = /* GraphQL */ `mutation DeleteBookingConfig(
  $input: DeleteBookingConfigInput!
  $condition: ModelBookingConfigConditionInput
) {
  deleteBookingConfig(input: $input, condition: $condition) {
    orgUnitId
    orgUnitAdmin
    maxDaysInAdvance
    maxBookableDays
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingConfigMutationVariables,
  APITypes.DeleteBookingConfigMutation
>;
export const createMailConfig = /* GraphQL */ `mutation CreateMailConfig(
  $input: CreateMailConfigInput!
  $condition: ModelMailConfigConditionInput
) {
  createMailConfig(input: $input, condition: $condition) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMailConfigMutationVariables,
  APITypes.CreateMailConfigMutation
>;
export const updateMailConfig = /* GraphQL */ `mutation UpdateMailConfig(
  $input: UpdateMailConfigInput!
  $condition: ModelMailConfigConditionInput
) {
  updateMailConfig(input: $input, condition: $condition) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMailConfigMutationVariables,
  APITypes.UpdateMailConfigMutation
>;
export const deleteMailConfig = /* GraphQL */ `mutation DeleteMailConfig(
  $input: DeleteMailConfigInput!
  $condition: ModelMailConfigConditionInput
) {
  deleteMailConfig(input: $input, condition: $condition) {
    userId
    userMail
    isReceiveOwnBooking
    isReceiveForeignBooking
    isReceiveMeetingRoomBooking
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMailConfigMutationVariables,
  APITypes.DeleteMailConfigMutation
>;
export const createDefaultRoomConfig = /* GraphQL */ `mutation CreateDefaultRoomConfig(
  $input: CreateDefaultRoomConfigInput!
  $condition: ModelDefaultRoomConfigConditionInput
) {
  createDefaultRoomConfig(input: $input, condition: $condition) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateDefaultRoomConfigMutationVariables,
  APITypes.CreateDefaultRoomConfigMutation
>;
export const updateDefaultRoomConfig = /* GraphQL */ `mutation UpdateDefaultRoomConfig(
  $input: UpdateDefaultRoomConfigInput!
  $condition: ModelDefaultRoomConfigConditionInput
) {
  updateDefaultRoomConfig(input: $input, condition: $condition) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateDefaultRoomConfigMutationVariables,
  APITypes.UpdateDefaultRoomConfigMutation
>;
export const deleteDefaultRoomConfig = /* GraphQL */ `mutation DeleteDefaultRoomConfig(
  $input: DeleteDefaultRoomConfigInput!
  $condition: ModelDefaultRoomConfigConditionInput
) {
  deleteDefaultRoomConfig(input: $input, condition: $condition) {
    userId
    roomId
    roomName
    neighborhoodId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteDefaultRoomConfigMutationVariables,
  APITypes.DeleteDefaultRoomConfigMutation
>;
export const createUserSettings = /* GraphQL */ `mutation CreateUserSettings(
  $input: CreateUserSettingsInput!
  $condition: ModelUserSettingsConditionInput
) {
  createUserSettings(input: $input, condition: $condition) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSettingsMutationVariables,
  APITypes.CreateUserSettingsMutation
>;
export const updateUserSettings = /* GraphQL */ `mutation UpdateUserSettings(
  $input: UpdateUserSettingsInput!
  $condition: ModelUserSettingsConditionInput
) {
  updateUserSettings(input: $input, condition: $condition) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSettingsMutationVariables,
  APITypes.UpdateUserSettingsMutation
>;
export const deleteUserSettings = /* GraphQL */ `mutation DeleteUserSettings(
  $input: DeleteUserSettingsInput!
  $condition: ModelUserSettingsConditionInput
) {
  deleteUserSettings(input: $input, condition: $condition) {
    userId
    mailConfig {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
    defaultRoomConfig {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
    consent
    createdAt
    updatedAt
    userSettingsMailConfigId
    userSettingsDefaultRoomConfigId
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSettingsMutationVariables,
  APITypes.DeleteUserSettingsMutation
>;
export const createInfoText = /* GraphQL */ `mutation CreateInfoText(
  $input: CreateInfoTextInput!
  $condition: ModelInfoTextConditionInput
) {
  createInfoText(input: $input, condition: $condition) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInfoTextMutationVariables,
  APITypes.CreateInfoTextMutation
>;
export const updateInfoText = /* GraphQL */ `mutation UpdateInfoText(
  $input: UpdateInfoTextInput!
  $condition: ModelInfoTextConditionInput
) {
  updateInfoText(input: $input, condition: $condition) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInfoTextMutationVariables,
  APITypes.UpdateInfoTextMutation
>;
export const deleteInfoText = /* GraphQL */ `mutation DeleteInfoText(
  $input: DeleteInfoTextInput!
  $condition: ModelInfoTextConditionInput
) {
  deleteInfoText(input: $input, condition: $condition) {
    id
    infoText
    important
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInfoTextMutationVariables,
  APITypes.DeleteInfoTextMutation
>;
export const createHint = /* GraphQL */ `mutation CreateHint(
  $input: CreateHintInput!
  $condition: ModelHintConditionInput
) {
  createHint(input: $input, condition: $condition) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHintMutationVariables,
  APITypes.CreateHintMutation
>;
export const updateHint = /* GraphQL */ `mutation UpdateHint(
  $input: UpdateHintInput!
  $condition: ModelHintConditionInput
) {
  updateHint(input: $input, condition: $condition) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHintMutationVariables,
  APITypes.UpdateHintMutation
>;
export const deleteHint = /* GraphQL */ `mutation DeleteHint(
  $input: DeleteHintInput!
  $condition: ModelHintConditionInput
) {
  deleteHint(input: $input, condition: $condition) {
    hintId
    roomId
    buildingId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteHintMutationVariables,
  APITypes.DeleteHintMutation
>;
